.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding-bottom: 0 !important;
  background-color: var(--background-secondary);
  overflow-x: hidden;
  @media only screen and (max-width: 768px) {
    border-radius: 10px;
    box-shadow: 0px 0px 9px 2px rgb(0, 0, 0, 0.6);
  }
}

.mainMenuContainer {
  overflow-y: scroll;
  padding: 0 15px;
  display: flex;
  gap: 18px;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.scroll {
  padding-right: 3px; /* the right side padding is 3px becasue of the custom scrollbar */

  /* width */
  &.mainMenuContainer::-webkit-scrollbar {
    width: 12px;
  }

  /* Track */
  &.mainMenuContainer::-webkit-scrollbar-track {
    background: none;
  }

  /* Handle */
  &.mainMenuContainer::-webkit-scrollbar-thumb {
    background: var(--background-quaternary);
    background-clip: content-box;
    border: 4px solid transparent;
  }

  /* Handle on hover */
  &.mainMenuContainer::-webkit-scrollbar-thumb:hover {
    background: var(--background-quaternary-hover);
    background-clip: content-box;
    border: 3.5px solid transparent;
  }
}

.mainMenuContainer::-webkit-scrollbar {
  width: 0px;
}

.topMenuContainer {
  @media only screen and (max-width: 768px) {
    padding-top: 5px;
  }
}

.logoContainer {
  margin-top: 30px;
  @media only screen and (max-width: 768px) {
    display: none;
  }
}

.fullLogo {
  height: 60px;
  padding-left: 7px;
}

.logo {
  height: 50px;
  max-width: 100%;
  object-fit: contain;
  object-position: center;
  padding-left: 8px;
}

.dashboardContainer {
  margin: 10px 0 20px 0;
}

.productsMenuContainer {
  display: flex;
  flex-direction: column;
  gap: 5px;
  overflow: hidden;
}

.productsText {
  margin-left: 17px;
  margin-bottom: 3px;
}

.productsTextClosed {
  visibility: hidden;
}

.bottomMenuContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;
}

.upgradeButtonContainer {
  background: none;
  border-radius: 10px;
  transition: 0.05s linear;
}
.upgradeButtonContainer:hover {
  background: var(--background-tertiary);
  border-radius: 10px;
  box-shadow: none;
}

.userButtonContainer {
  display: block;
  cursor: pointer;
}

.userContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 20px;
  background-color: var(--background-tertiary);
  @media only screen and (max-width: 768px) {
    border-radius: 0 0 10px 10px;
  }
}

.userNameAndIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  /* font-size: 16px; */
}
