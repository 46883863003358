.card {
  width: 100%;
  max-width: 220px;
  @media only screen and (max-width: 768px) {
    max-width: 260px;
  }
}

.contentContainer {
  display: flex;
  flex-direction: column;
  gap: 3px;
  justify-content: space-between;
}

.textContainer {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.buttonContainer {
  display: flex;
  text-align: center;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
  @media only screen and (max-width: 768px) {
    margin-top: 0px;
  }
}
