.container {
  padding: 50px;
}

.title {
  font-size: 24px;
  font-weight: bold;
}

.categoriesListContainer {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 30px;
}

.buttonContainer {
  display: flex;
}