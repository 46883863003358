.container {
  display: flex;
  flex-direction: column;
}

.label {
  margin-left: 15px;
}

.input {
  padding: 12px;
  flex: 1;
}

.inputHasLabel {
  margin-top: 10px;
}

.inputHasSearchIcon {
  padding-left: 30px;
}

.input::placeholder {
  opacity: 1;
  color: var(--text-secondary);
}

.regular {
  background-color: #ffffff22;
  color: var(--white);
  border: none;
  border-radius: 8px;
}

.transparent {
  background-color: transparent;
  color: var(--white);
  border: none;
  border-bottom: 1px solid var(--border-secondary);
}

.searchContainer {
  display: flex;
  align-items: center;
}

.searchContainer > img {
  transform: translate(18px, -1px);
}

.searchInput {
  padding: 12px;
  padding-left: 26px;
  flex: 1;
  background-color: transparent;
  color: var(--white);
  border: none;
  border-bottom: 1px solid var(--border-secondary);
}
