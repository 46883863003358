.container {
  display: flex;
  border: 1px solid #707070;
  border-radius: 8px;
  cursor: pointer;
  padding: 10px;
  margin-top: 10px;
}

.flex {
  display: flex;
  align-items: center;
  flex: 1;
}