@media only screen and (max-width: 610px) {
  .container {
    padding: 25px 45px 25px 35px;
    border-radius: var(--cornerRadius);
    transition: 0.4s;
    display: flex;
    align-items: center;
    gap: 25px;
    background: var(--cardBg);
  }

  .content {
    text-align: left;
  }
}
@media only screen and (min-width: 610.1px) {
  .container {
    padding: 25px 0px;
    width: 170px;
    height: 120px;
    box-shadow: inset 0px 0px 0px 1px var(--darkGrey);
    border-radius: var(--cornerRadius);
    transition: 0.4s;
  }

  .content {
    margin-top: 15px;
  }

  .container:hover {
    background: var(--cardBg);
    box-shadow: none;
  }
}

.icon {
  height: 50px;
}

.content {
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  gap: 8px;
}
