.container {
  border-radius: var(--borderRadFilters);
  width: 100%;
  height: 280px;
}

.expandedContainer {
  height: 400px;
}

.contentContainer {
  height: 210px;
  padding: 10px;
  overflow: auto;
  -webkit-mask-image: linear-gradient(
    transparent,
    black 3%,
    black 97%,
    transparent
  );
  mask-image: linear-gradient(transparent, black 3%, black 97%, transparent);
}

.expandedContentContainer {
  height: 330px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 25px;
  border-top-left-radius: var(--borderRadFilters);
  border-top-right-radius: var(--borderRadFilters);
  padding: 0 20px;
  box-shadow: none;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 25px;
  border-bottom-left-radius: var(--borderRadFilters);
  border-bottom-right-radius: var(--borderRadFilters);
  padding: 0 20px;
  box-shadow: none;
}

.expandButton {
  cursor: pointer;
  width: fit-content;
}

.closeButton {
  cursor: pointer;
}
