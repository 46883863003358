.container {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.imageContainer {
  position: relative;
  overflow: hidden;
  border: 2px solid transparent;
}

.imageWithTagContainer {
  border: 2px solid #FFF;
  border-radius: 6px;
}

.image {
  width: 150px;
  height: 99px;
  object-fit: contain;
  @media only screen and (min-width: 768px) {
    width: 250px;
    height: 166px;
  }
}

.titleText {
  font-size: 16px;
  color: var(--text-primary);
  font-weight: 400;
}

.selectedTextContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(8px);
  z-index: 1;
}

.selectedText {
  font-size: 18px;
  font-weight: bold;
}

.tagContainer {
  position: absolute;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  height: 100%;
  width: 100%;
  z-index: 2;
}

.tagText {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  font-size: 12px;
  color: var(--red);
  background-color: white;
  border-top-right-radius: 6px;
  padding: 3px;
  @media only screen and (min-width: 768px) {
    font-size: 14px;
    padding: 6px;
  }
}