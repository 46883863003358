:root {
  --heroHeight: 450px;
  --stackedHeroHeight: calc(100vh - var(--pageTopMargin) - 50px);
  --minStackedHeroHeight: 650px;
}

.banner {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -2;
  width: 100%;
  object-fit: cover;
  object-position: center -10px;
  height: 100vh;
  max-height: calc(var(--heroHeight) + var(--pageTopMargin) + 100px);
  opacity: 70%;
  filter: blur(2px);
  scale: 1.01;
}

.coverGradient {
  background-image: linear-gradient(
    #08080800,
    var(--bgColor) 50%,
    var(--bgColor) 100%
  );
  height: 200px;
  position: absolute;
  left: 0;
  top: calc((var(--heroHeight) + var(--pageTopMargin)) - 50px);
  z-index: -1;
  width: 100%;
}

.heroContainer {
  margin-top: 40px;
  display: flex;
  height: var(--heroHeight);
  max-height: 500px;
  min-height: 400px;
  position: relative;
  margin-bottom: -30px;
}

.heroContentContainer {
  display: flex;
  flex-direction: column;
}

.stackedHeroContainer {
  height: var(--stackedHeroHeight);
  min-height: var(--minStackedHeroHeight);
  position: relative;
}

.stackedCoverGradient {
  background-image: linear-gradient(
    #08080800,
    var(--bgColor) 25%,
    var(--bgColor) 100%
  );
  height: 260px;
  position: absolute;
  left: calc(-1 * var(--pagePadding));
  bottom: 0;
  z-index: -1;
  width: calc(100% + calc(2 * var(--pagePadding)));
}

.stackedHeroContentContainer {
  height: calc(100% - var(--pagePadding));
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* margin: var(--pagePadding) 0px; */
}

.stackedHeroLowContent {
  display: flex;
  flex-direction: column;
  gap: 30px;
  z-index: 2;
}

.stackedGetStarted {
}

.stackedFootballPlayerContainer {
  position: absolute;
  top: calc(var(--pagePadding) + 140px);
  width: 100%;
  display: flex;
  justify-content: center;
}

.stackedFootballPlayer {
  min-width: 300px;
  width: calc(100px + 30vh);
  max-width: 80%;
}

.stackingCardsContainer {
  margin-top: -150px;
  margin-bottom: -180px;
  @media only screen and (min-width: 735px) {
    display: none;
  }
}

@media only screen and (max-width: 734px) {
  /* Smallest version */

  .heroContainer {
    display: none;
  }

  .coverGradient {
    display: none;
  }
}

@media only screen and (min-width: 735px) {
  /* larger versions */

  .stackedHeroContainer {
    display: none;
  }

  .stackedCoverGradient {
    display: none;
  }

  @media only screen and (max-width: 900px) {
    /* middle version */
    .headerText1 {
      display: none;
    }
  }
  @media only screen and (min-width: 900.1px) {
    /* largest version */
    .headerText2 {
      display: none;
    }
  }
}

.footballPlayer {
  position: absolute;
  right: -70px;
  bottom: 0;
  width: clamp(440px, 40vw, 530px);
  z-index: -2;
}

.tagline {
  margin-top: 22px;
  font-size: 20px;
  max-width: 440px;
  /* width: calc(40px + 35vw); */
  width: 50vw;
}

.wwdSidePanel {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  direction: rtl;
}

.wwdSidePanel > * {
  direction: ltr;
}

.wwdLowPanel {
  display: flex;
  margin-top: var(--cardSpacing);
  gap: var(--cardSpacing);
}

.wwdLowPanelContent {
  background: var(--cardBg);
  min-width: min-content;
  border-radius: 10px;
  padding: 16px 20px;
  text-align: center;
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
}

.wwdLowPanelContent > img {
  width: 25px;
}

.wwdSpacer {
  width: 250px;
  height: 20px;
}

.aboutButtonContainer {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  gap: var(--cardSpacing);
}

@media only screen and (max-width: 900px) {
  .aboutContentContainer {
    background: var(--cardBg);
  }
  .wwdSidePanel {
    display: none;
  }
  .wwdSpacer {
    display: none;
  }

  .aboutButtonContainerShorter {
    display: none;
  }

  @media only screen and (max-width: 582px) {
    .wwdLowPanelText {
      display: none;
    }

    .wwdLowPanelContent {
      gap: 0;
    }
    .aboutButtonContainer {
      display: none;
    }

    .aboutButtonContainerShorter {
      margin-top: 30px;
      display: flex;
      gap: var(--cardSpacing);
      flex-wrap: wrap;
    }
  }
}

@media only screen and (min-width: 900px) {
  .aboutContentContainer {
    box-shadow: inset 0px 0px 0px 1px var(--darkGrey);
    mask-image: linear-gradient(to right, black 70%, transparent 90%);
  }

  .wwdLowPanel {
    display: none;
  }

  .aboutButtonContainerShorter {
    display: none;
  }
}

.whatWeDoContainer {
  position: relative;
  display: flex;
  justify-content: space-between;
  gap: 40px;
  border-radius: var(--cornerRadius);
}

.aboutContentContainer {
  width: 100%;
  padding: 40px 40px 50px 40px;
  border-radius: var(--cornerRadius);
}

.aboutContent {
  display: flex;
  justify-content: space-between;
  gap: 30px;
}

.aboutTextContainer {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 700px;
}

/* .wwdSidePanel2 {
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  margin-top: var(--cardSpacing);
  gap: var(--cardSpacing);
  width: 100%;
  max-width: 250px;
}

.wwdSidePanelText {
  background: var(--cardBg);
  width: 100%;
  border-radius: 10px;
  padding: 14px 0;
  text-align: center;
}

.wwdSidePanelText > * {
  font-weight: 500;
  font-size: 20px;
} */

.readyAnimationContainer {
  width: 120%;
  margin-left: -10%;
}

.ReadyImg {
  margin-right: 20px;
  margin-left: 8.33%;
}

.ArrowImg {
  fill: var(--athleteRed);
  position: absolute;
  left: 60%;
  margin-right: 8.33%;
}

.SeePlansImg {
  position: absolute;
  right: -30%;
  bottom: 0;
  opacity: 0;
}

@media only screen and (max-width: 1070px) {
  .readyAnimationContainer {
    width: 100%;
    margin-left: 0;
  }

  .readyAnimation {
    display: flex;
    flex-direction: column;
    gap: 20px;
    place-items: center;
  }

  .ReadyImg {
    width: 90%;
    max-width: 600px;
    margin: 0;
  }
  .ArrowImg {
    display: none;
  }
  .SeePlansImg {
    display: none;
  }
}

@media only screen and (min-width: 1070px) {
  .readyButton {
    display: none;
  }

  .readyAnimation {
    overflow: hidden;
    position: relative;
    width: 100%;
    display: flex;
    cursor: pointer;
  }

  .readyAnimation > svg {
    height: 220px;
    transition: 0.5s cubic-bezier(0.49, 1.42, 0.53, 1.01);
  }

  .readyAnimation {
    &:hover .SeePlansImg {
      opacity: 1;
      right: 0;
      margin-right: 8.33%;
    }
    &:hover .ReadyImg {
      opacity: 0;
      transform: translateX(10%);
    }
    &:hover .ArrowImg {
      fill: white;
      opacity: 1;
      left: -8%;
    }
  }

  .readyAnimation {
    &:active .SeePlansImg {
      animation: shake 1s infinite;
    }
    &:active .ArrowImg {
      opacity: 0.5;
    }
  }

  .readyAnimation:before,
  .readyAnimation:after {
    position: absolute;
    top: 0;
    width: 100px;
    height: 100%;
    content: "";
    z-index: 2;
  }

  .readyAnimation:before {
    left: 0;
    background: linear-gradient(90deg, var(--bgColor) 15%, transparent);
  }

  .readyAnimation:after {
    right: 0;
    background: linear-gradient(270deg, var(--bgColor) 15%, transparent);
  }
}

/* ----------------------------------------------- */
/* ----------------------------------------------- */
/* ----------------------------------------------- */
.ReadyImg1 {
  border: 1px solid green;
  margin-right: 20px;
  margin-left: 8.33%;
}

.ArrowImg1 {
  fill: var(--athleteRed);
  position: absolute;
  left: 60%;
  margin-right: 8.33%;
  border: 1px solid red;
}

.SeePlansImg1 {
  position: absolute;
  right: -30%;
  bottom: 0;
  opacity: 0;
}

@media only screen and (max-width: 600px) {
  .readyAnimation {
    display: flex;
    flex-direction: column;
    gap: 20px;
    place-items: center;
  }

  .ReadyImg {
    width: 90%;
    margin: 0;
  }
  .ArrowImg {
    display: none;
  }
  .SeePlansImg {
    display: none;
  }
}

@media only screen and (min-width: 600px) {
  .readyButton1 {
    display: none;
  }

  .readyAnimation1 {
    overflow: hidden;
    position: relative;
    width: 120%;
    display: flex;
    margin-left: -10%;
    cursor: pointer;
  }

  .readyAnimation1 > svg {
    height: 220px;
    transition: 0.5s cubic-bezier(0.49, 1.42, 0.53, 1.01);
  }

  .readyAnimation1 {
    &:hover .SeePlansImg1 {
      opacity: 1;
      right: 0;
      margin-right: 8.33%;
    }
    &:hover .ReadyImg1 {
      opacity: 0;
      transform: translateX(10%);
    }
    &:hover .ArrowImg1 {
      fill: white;
      opacity: 1;
      left: -8%;
    }
  }

  .readyAnimation1 {
    &:active .SeePlansImg {
      animation: shake 1s infinite;
    }
    &:active .ArrowImg1 {
      opacity: 0.5;
    }
  }

  .readyAnimation1:before,
  .readyAnimation1:after {
    position: absolute;
    top: 0;
    width: 100px;
    height: 100%;
    content: "";
    z-index: 2;
  }

  .readyAnimation1:before {
    left: 0;
    background: linear-gradient(90deg, var(--bgColor) 15%, transparent);
  }

  .readyAnimation1:after {
    right: 0;
    background: linear-gradient(270deg, var(--bgColor) 15%, transparent);
  }
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}
