.container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.outline {
  padding: 10px 20px;
  border: 1px solid var(--border-primary);
  border-radius: 15px;
}

.getMoreContainer {
  cursor: pointer;
  margin-left: 10px;
}

.getMore {
  text-decoration: underline;
  color: var(--red);
}