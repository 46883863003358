.container {
  display: flex;
  flex-direction: column;
  padding: 25px 0px 30px 0px;
  width: 100%;
  box-shadow: inset 0px 0px 0px 1px var(--darkGrey);
  border-radius: var(--cornerRadius);
  align-items: center;
  transition: 0.2s;
}

.icon {
  height: 50px;
}

.descriptionContainer {
  margin-top: 15px;
  text-align: center;
  /* width: 75px; */
}
