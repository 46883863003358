.container {
  padding: 50px;
}

.title {
  font-size: 30px;
  font-weight: bold;
}

.input {
  margin-top: 15px;
  width: 33%;
  min-width: 400px;
}

.tagInputContainer {
  margin-left: 15px;
  margin-top: 15px;
  width: 33%;
  min-width: 400px;
}

.tagsContainer {
  display: flex;
  margin-top: 10px;
  gap: 10px;
}

.tag {
  display: flex;
  gap: 10px;
  padding: 5px;
  border: 1px solid #202020;
  border-radius: 5px;
}

.removeTagContainer {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.removeTag {
  font-size: 8px;
}

.buttonContainer {
  display: flex;
  margin-top: 30px;
}