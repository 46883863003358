.container {
  margin-top: 15px;
  padding: 30px;
  background-color: var(--background-secondary);
  border-radius: 12px;
}

.containerWithHeading {
  padding-top: 15px;
}

.title {
  margin: 25px 0 0 20px;
}

.regular {
  margin-bottom: 5px;
}

.heading {
  margin: 15px 0 8px 0;
}

.bullet {
  margin-right: 5px;
  font-size: 18px;
  line-height: 16px;
}

.bulletItemWithTitleContainer {
  display: flex;
}

.bulletItemWithTitleTitle {
  font-weight: 500;
  /* color: #fff; */
}

.bulletItemWithTitleText {
  color: #aaa;
  padding-bottom: 8px;
}

.itemWithTitleTitle {
  font-weight: 600;
  /* color: #fff; */
}

.itemWithTitleText {
  /* color: #aaa; */
}
