:root {
  --padding: 40px;
}

.container {
  padding: var(--padding);
  border-radius: var(--cornerRadius);
  /* WIDTH EQUATION: ((screensize - var(--cardSpacing) - var(--cardSpacing)) / 3) - (2 * var(--padding) + 20) */
  width: 243.33px;
  background: var(--cardBg);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.outline {
  background: none;
  box-shadow: inset 0px 0px 0px 1px var(--darkGrey);
}

.contentContainer {
  margin-top: 18px;
  margin-bottom: 70px;
  z-index: 1;
}

.extraContentSpacer {
  /* margin-top: 70px; */
  position: absolute;
  bottom: var(--padding);
}

.extraContentContainer {
  margin-top: 70px;
}
