.container {
  width: 100%;
}

.text {
  /* font-size: 56px; */
  font-size: clamp(16px, calc(24px + 3vw), 60px);
  font-weight: 600;
  line-height: 110%;

  /* width: max-content; */
  text-align: center;
  background-image: var(--whiteGradient);
  background-size: cover;

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;

  /* border: 1px solid red; */
}
