.container {
  display: flex;
  gap: 10px;
  margin-top: 15px;
}

.tag {
  padding: 5px 15px;
  border: 1px solid #707070;
  border-radius: 5px;
}