.container {
  font-family: Inter, sans-serif;
}

.regular {
  color: var(--text-primary);
  font-size: var(--regular);
  font-weight: normal;
}

.description {
  color: var(--text-secondary);
  font-size: var(--regular);
  font-weight: normal;
}

.descriptionSmall {
  color: var(--text-secondary);
  font-size: var(--description);
  font-weight: normal;
}

.title {
  color: var(--text-primary);
  font-size: var(--title);
  font-weight: 600;
}

.h1 {
  color: var(--text-primary);
  font-size: var(--h1);
  font-weight: 600;
}

.h2 {
  color: var(--text-primary);
  font-size: var(--h2);
  font-weight: 600;
}

.h3 {
  color: var(--text-primary);
  font-size: var(--h3);
  font-weight: 600;
}

.h4 {
  color: var(--text-primary);
  font-size: var(--regular);
  font-weight: 600;
}
