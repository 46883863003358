.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: var(--white);
  border-radius: 10px;
}

.date {
  padding: 10px 10px 0 0;
  align-self: self-end;
  font-size: 16px;
}

.content {
  padding: 12px;
  @media only screen and (min-width: 768px) {
    padding: 20px;
  }
}

.title {
  font-size: 18px;
  color: var(--black);
  font-weight: bold;
  @media only screen and (min-width: 768px) {
    font-size: 22px;
  }
}

.bodyTitle {
  font-size: 16px;
  color: var(--black);
  font-weight: bold;
  @media only screen and (min-width: 768px) {
    font-size: 18px;
  }
}

.description {
  font-size: 14px;
  color: var(--black);
  @media only screen and (min-width: 768px) {
    font-size: 16px;
  }
}

.image {
  max-width: 100%;
  max-height: 200px;
  object-fit: contain;
  @media only screen and (min-width: 768px) {
    max-width: 350px;
    max-height: 233px;
  }
}

.imageDescriptionContainer {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  @media only screen and (min-width: 768px) {
    flex-direction: row;
    gap: 20px;
  }
}

.bodyContainer {
  margin-top: 20px;
}