:root {
  --cardPadding: 25px;
  --cardPaddingExtra: 30px;
}

.container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: var(--background-secondary-translucent);
  border-radius: 20px;
  width: 100%;

  @media only screen and (min-width: 768px) {
    padding: var(--cardPadding);
    flex-direction: column;
    padding-bottom: var(--cardPaddingExtra);
  }
}

.outlined {
  background-color: transparent;
  border: 1px solid #555555;
}

.clickContainer {
  cursor: pointer;
  transition: 0.1s linear;
  &:hover {
    background: rgba(255, 255, 255, 0.15);
  }
}

.mainContentContainer {
  padding: var(--cardPadding);
  padding-right: 0;
  @media only screen and (min-width: 768px) {
    padding: 0px;
  }
}

.hasMobileArrow {
  @media only screen and (max-width: 768px) {
    max-width: calc(100% - var(--cardPadding) - 50px - 20px);
  }
}

.arrowContainer {
  width: 50px;
  max-width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--background-tertiary-translucent);
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;

  @media only screen and (min-width: 768px) {
    display: none;
    padding: 15px 25px;
  }
}

.contentContainer {
  margin-top: 10px;
}

.extraContentContainer {
  display: block;
  width: 100%;

  @media only screen and (min-width: 768px) {
    margin-top: 10px;
    padding: 0;
  }
}

.extraContentContainerPadding {
  padding: 0 20px 20px 20px;
  @media only screen and (min-width: 768px) {
    padding: 0;
  }
}
