.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  margin-top: 50px;
}

.dragContainer {
  border: 3px dashed #CCC;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 20px;
  width: 500px;
  display: flex;
  justify-content: center;
}

.hoverDragContainer {
  border: 3px dotted #CCC;
}

.rowContainer {
  display: flex;
  margin-top: 18px;
  justify-content: space-between;
  align-items: center;
}

.fileName {
  font-weight: bold;
}

.fileStatusContainer {
  display: flex;
  height: 27px;
}

.fileStatusListContainer {
  border: 1px solid white;
  width: 700px;
  max-width: 700px;
  max-height: 80vh;
  overflow: scroll;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 10px;
}

.uploadAreaContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button {
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 10px;
  cursor: pointer;
}

.buttonDisabled {
  background-color: #CCC;
  color: #999;
  cursor: not-allowed;
}