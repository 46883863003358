.container {
  display: flex;
  gap: 10px;
}

.errorContainer {
  display: flex;
  align-items: center;
}

.errorIcon {
  color: red;
  font-weight: bold;
}

.errorMessage {
  margin-right: 10px;
}