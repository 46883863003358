.container {
  width: 100%;
}

.innerContainer {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--borderRadFilters) 0 0 var(--borderRadFilters);
}

.emptyContainer {
  margin-top: 20px;
  text-align: center;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  padding: 15px;
}

.textContainer {
  width: min-content;
  text-align: center;
}

.savedSchoolContainer {
  display: flex;
  align-items: center;
  padding: 10px;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  border-radius: 8px;
  margin-bottom: 8px;
  transition: 0.1s linear;
  box-shadow: 0 0 0 1px #646464;
  cursor: pointer;
}
.savedSchoolContainer:hover {
  background-color: var(--background-quaternary);
  box-shadow: none;
}

.savedSchoolContainerDark {
  box-shadow: 0 0 0 1px #a8aaad;
}
.savedSchoolContainerDark:hover {
  background-color: var(--background-quaternary-light);
  box-shadow: none;
}

.schoolName {
  font-size: 13px;
}

/* .schoolName:hover {
  font-weight: 600;
} */

.buttonsContainer {
  height: min-content;
  display: flex;
  gap: 5px;
  align-items: stretch;
}

.actionButton {
  color: white;
  font-weight: 300;
  display: flex;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
}

.directionsButton {
  padding: 6px;
  background-color: #2b2b2b;
  &:hover {
    background-color: #1f1f1f;
  }
}
.saveButton {
  padding: 6px;
  background-color: #2b2b2b;
  &:hover {
    background-color: #1f1f1f;
  }
}

.icon {
  width: 17px;
  height: 17px;
}

.popupContainer {
  width: 220px;
  position: absolute;
  right: 90px;
  top: 130px;
}

.wiggle {
  animation: wiggle 0.15s ease-in-out;
}

@keyframes wiggle {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(3px);
  }
  50% {
    transform: translateX(-3px);
  }
  75% {
    transform: translateX(3px);
  }
  100% {
    transform: translateX(0);
  }
}
