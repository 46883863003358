.container {
  background-color: #202020;
  width: 450px;
  padding: 30px;
  border-radius: 24px;
}

.title {
  font-size: 24px;
  font-weight: bold;
}

.input {
  margin-top: 20px;
}

.buttonsContainer {
  display: flex;
  gap: 20px;
  justify-content: end;
  align-items: center;
  margin-top: 40px;
}

.iconsContainer {
  display: grid;
  grid-template-columns: repeat(8, 40px);
  gap: 20px;
  margin-top: 40px;
}

.iconContainer {
  cursor: pointer;
  padding: 5px;
  border-radius: 5px;

  &:hover {
    background-color: #444;
  }
}

.selectedIcon {
  background-color: #777;
}