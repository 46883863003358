.pageDescriptionContainer {
  margin-top: 20px;
}

.selectNewsletterTitleContainer {
  margin-top: 35px;

  @media only screen and (min-width: 768px) {
    margin-top: 80px;
  }
}

.selectNewsletterTitle {
  font-weight: bold;
  font-size: 24px;
}

.filtersContainer {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media only screen and (min-width: 768px) {
    justify-content: space-between;
    flex-direction: row;
  }
}

.filtersButtonsContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media only screen and (min-width: 768px) {
    flex-direction: row;
  }
}

.filterButton {
  display: flex;
}

.selectedFilterButton {
  background-color: #FFF !important;
}

.selectedFilterButtonText {
  color: #000;
}

.searchInput {
  width: 100%;

  @media only screen and (min-width: 768px) {
    width: 250px;
  }
}

.newsletterHorizontalListContainer {
  overflow-x: scroll;
  margin-top: 15px;
  display: flex;
  gap: 10px;

  @media only screen and (min-width: 768px) {
    gap: 30px;
  }
}

.newsletterVerticalListContainer {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.selectedNewsletterContainer {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  gap: 20px;
}