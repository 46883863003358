.container {
  position: relative;
  display: none;

  @media (min-width: 1000px) {
    display: block;
  }
}

.contentContainer {
  z-index: 98;
  bottom: 0;
  position: absolute;

  display: flex;
  align-items: center;
}

.slideOpen {
  -webkit-transition: left 1s ease-in-out;
  -moz-transition: left 1s ease-in-out;
  -o-transition: left 1s ease-in-out;
  transition: left 1s ease-in-out;

  left: 0;
}

.slideClose {
  -webkit-transition: left 1s ease-in-out;
  -moz-transition: left 1s ease-in-out;
  -o-transition: left 1s ease-in-out;
  transition: left 1s ease-in-out;

  left: calc(-1 * calc(42vw - calc(2 * var(--medCardSpacing)) + 20px));

  @media (min-width: 1700px) {
    left: max(calc(-1 * calc(35vw - calc(2 * var(--medCardSpacing)) + 20px)),
        calc(-1 * calc(700px - calc(2 * var(--medCardSpacing)) + 20px)));
  }
}

.halfCloseAnimation {
  -webkit-transition: left 0.3s ease-out;
  -moz-transition: left 0.3s ease-out;
  -o-transition: left 0.3s ease-out;
  transition: left 0.3s ease-out;

  left: -50px;
}

.halfOpenAnimation {
  -webkit-transition: left 0.3s ease-in;
  -moz-transition: left 0.3s ease-in;
  -o-transition: left 0.3s ease-in;
  transition: left 0.3s ease-in;

  left: 0vw;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.contentContainer::-webkit-scrollbar {
  display: none;
}

.container:hover .closeButton {
  display: flex;
}

.closeButton {
  display: none;
  position: absolute;
  background-color: var(--background-primary);
  left: 42vw;
  top: calc(50vh - 15px);
  width: 30px;
  height: 80px;
  z-index: 999;
  border-radius: 0 15px 15px 0;
  cursor: pointer;
  align-items: center;

  @media (min-width: 1700px) {
    left: min(calc(35vw - calc(2 * var(--medCardSpacing)) + 30px),
        calc(700px - calc(2 * var(--medCardSpacing)) + 30px));
  }
}

.closeButton2 {
  width: 20px;
  height: 80px;
  background-color: var(--background-primary);
  border-radius: 0 15px 15px 0;
  z-index: 100;
}

.container:hover .closeButton2Transform {
  opacity: 1;
}

.container:hover .closeButton2ImgTransform {
  transform: translateX(3px);
}

.closeButton2 {
  /* border: 1px solid red; */
  cursor: pointer;
  z-index: 99;
  transform: translate(-10px);
  display: flex;
  align-items: center;
  position: relative;
  height: 80px;
  width: 30px;
  background-color: var(--background-primary);
  border-radius: 0 15px 15px 0;
  transition: 0.05s linear;
}

.closeButton2Transform {
  opacity: 0.5;
}

.closeButton2Img {
  transform: translateX(7px);
  transition: 0.1s linear;
}

.closeButton2ImgClosed {
  border: 1px solid red;
}

.closeButton2::before {
  transform: translate(10px);
  content: "";
  position: absolute;

  background-color: transparent;
  top: -20px;
  left: -0.5px;
  height: 20px;
  width: 9px;
  border-bottom-left-radius: 10px;
  box-shadow: 0 10px 0 0 var(--background-primary);
  /* box-shadow: 0 10px 0 0 red; */
}

.closeButton2::after {
  transform: translate(10px);
  content: "";
  position: absolute;

  left: -0.5px;
  background-color: transparent;
  bottom: -20px;
  height: 20px;
  width: 9px;
  border-top-left-radius: 10px;
  box-shadow: 0 -10px 0 0 var(--background-primary);
}