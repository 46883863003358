.container {
  display: flex;
  flex-direction: column;
  padding: 30px 50px;
}

.textContainer {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 10px;
}

.navBarSpacer {
  @media only screen and (max-width: 768px) {
    height: 80px;
  }
}

.videoContainer {
  position: relative;
  height: 50vh;
  width: 100%;
}

.videoIframeContainer {
  height: 50vh;
  width: 100%;
}

.upNextContainer {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  backdrop-filter: blur(50px);
  z-index: 99;
  height: 100%;
  width: 100%;
}

.upNextCardContainer {
  background-color: var(--background-primary);
  border-radius: 20px;
  padding: 20px;
}

.cancelButton {
  margin-top: 20px;
}

.tagsContainer {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 15px;
}

.tagContainer {
  display: flex;
  padding: 5px 15px;
  border: 1px solid var(--text-primary) !important;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.moreButtonContainer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.moreButton {
  padding: 10px 50px;
}

.sectionContainer {
  margin-top: 50px;
}

.moreFromThisSectionMobileContainer {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  gap: 15px;

  @media only screen and (min-width: 768px) {
    display: none;
  }
}

.moreFromThisSectionContainer {
  margin-top: 50px;
  display: none;

  @media only screen and (min-width: 768px) {
    display: block;
  }
}
