.container {
  margin-top: calc(var(--sectionSpacing) + 30px);
  font-family: "Inter", sans-serif;
}

.banner {
  /* TO TURN OFF BANNER, uncomment the line below: */
  display: none !important;

  background: var(--redGradient);
  padding: 10px 50px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.emailIcon {
  width: 20px;
}

.footerContainer {
  display: flex;
  background: black;
  padding-top: 34px;
  padding-bottom: 50px;
  width: 100%;
}

.contentContainer {
  width: 100%;
  height: fit-content;

  /* display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap; */

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  justify-content: center;
  justify-items: stretch;

  font-size: 13px;
  padding: 0px 50px;
  gap: 30px;
}

.left {
  min-width: min-content;
}

.textContainer {
  margin-top: 4px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.contactContainer {
  display: flex;
  gap: 12px;
}
.contactContainer > * {
  min-width: max-content;
}

.pagesContainer {
  display: flex;
  font-weight: 700;
}

@media only screen and (min-width: 1000px) {
  .pagesContainer {
    gap: 100px;
  }
  .right {
    justify-self: right;
  }
}
@media only screen and (max-width: 999px) {
  .pagesContainer {
    justify-content: space-between;
  }
}
.logo {
  width: 120px;
}

.column {
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  gap: 10px;
}
