.container {
  display: flex;
}

.button {
  padding: 15px 20px !important;
  border-radius: 12px !important;
}

.selected {
  background-color: var(--background-secondary) !important;
}
