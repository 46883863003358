.container {
  display: flex;
  flex-direction: column;
  width: 93px;
  height: 93px;
  box-shadow: inset 0px 0px 0px 1px var(--darkGrey);
  border-radius: var(--cornerRadius);
  align-items: center;
  justify-content: center;
  transition: 0.4s;
  cursor: pointer;
  overflow: hidden;
}

.container:hover {
  width: min-content;
  padding: 0px calc(calc(93px - 50px) / 2) 0px
    calc(calc(calc(93px - 50px) / 2) + 10px);
}

.stationaryContainer {
  display: flex;
  align-items: center;
  gap: 15px;
  text-wrap: nowrap;
}

.icon {
  height: 50px;
  width: 50px;
  z-index: 1;
  user-select: none;
  position: absolute;
  right: 0;
  margin-right: calc(calc(93px - 50px) / 2);
}

.hiddenIcon {
  height: 50px;
  width: 50px;
  user-select: none;
  visibility: hidden;
}

.textContainer {
  transform: translateX(calc(100% + 30px));
  visibility: hidden;
  opacity: 0;
  transition: 0.4s;
}

.container:hover .textContainer {
  visibility: visible;
  transform: translateX(0);
  opacity: 1;
}
