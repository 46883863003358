.sectionContainer {
  margin-top: 50px;
}

.videosCategoryContainer {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}

.recentlyAddedContainer {
  display: flex;
  overflow-x: scroll;
  gap: 20px;
}

.videoCategoryHeader {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-bottom: 10px;
}

.seeAllContainer {
  border-radius: 100px !important;
}
