.container {
  background-color: #202020;
  width: 350px;
  padding: 30px;
  border-radius: 24px;
}

.title {
  font-size: 24px;
  font-weight: bold;
}

.input {
  margin-top: 20px;
}

.buttonsContainer {
  display: flex;
  gap: 20px;
  justify-content: end;
  align-items: center;
  margin-top: 40px;
}