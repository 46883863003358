.container {
  padding: 40px
}

.tabsContainer {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.tab {
  cursor: pointer;
}

.tabSelected {
  text-decoration: underline;
}