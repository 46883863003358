.youtubeInfo {
  text-align: center;
  color: var(--darkGrey);
}

.subCountContainer {
  margin: 14px 0px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.liveTag {
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 1px;
  width: min-content;
  padding: 2px 8px;
  display: flex;
  align-items: center;
  gap: 3px;
}

.blinkingDot {
  position: relative;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: var(--athleteRed);
  animation: flashing 1.5s infinite;
}

@keyframes flashing {
  20% {
    /* scale: 1; */
    opacity: 1;
  }
  50% {
    opacity: 0;
  }

  80% {
    /* scale: 1; */
    opacity: 1;
  }
}

a {
  text-decoration: none;
}
