.container {
  padding: 40px;
}

.title {
  font-size: 24px;
  font-weight: bold;
}

.subtitle {
  display: block;
  font-size: 16px;
  margin-top: 10px;
}

.positionContainer {
  padding-left: 15px;
  border-radius: 100px;
  margin-top: 10px;
  width: 30vw;
  max-width: 300px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.positionContainerEdit {
  background-color: none;
  border: 1px solid #333333;
}

.positionContainerAdd {
  background-color: var(--background-secondary-translucent);
  border: 1px solid transparent;
}

.saveButtonContainer {
  display: flex;
  margin-top: 30px;
}

.videoTypeContainer {
  margin-top: 50px;
}

.videoTypeTitle {
  font-size: 20px;
}
