.container {
  font-size: 38px;
  font-weight: 600;

  background-image: var(--whiteGradient);
  max-width: max-content;
  background-size: cover;

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}
