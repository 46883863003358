:root {
  --cellWidth: 450px;
}

.container {
  background: var(--formBg);
  font-size: 14px;
  max-width: calc(100% - 30px);
  width: calc(100% - 30px);
  padding: 12px 15px;
  border-radius: 8px;
  border: none;
  color: white;
}

/* .container:autofill {
  border: 1px solid var(--athleteRed);
}
.container:-webkit-autofill {
  border: 1px solid var(--athleteRed);
} */
