.container {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.titleContainer {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #fff;
  width: fit-content;
  padding: 0 5px;
  cursor: pointer;
  margin-bottom: 5px;
}

.titleContainerDark {
  border-bottom: 1px solid #000;
}

.title {
  margin-left: 10px;
  font-weight: 600;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  margin-left: 31px;
}

.stateContainer {
  margin-left: 10px;
}
