:root {
  --padding: 40px;
}

.stackedCardContainer {
  align-self: center;
  min-height: 300px;
  height: calc(100vh - 80px - 30px);
  width: 100%;
  max-width: 600px;
  box-sizing: border-box;
  padding: 40px 20px 20px 20px;
  border-radius: 15px;
  background: var(--background-secondary);
  position: relative;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 2px 35px 4px var(--background-primary);
}

.imgContainer {
  transition: all 0.1s;
  cursor: pointer;
  max-width: 500px;
  margin-top: 30px;
  width: 100%;
  flex: 1;
  min-height: 50px;
  border-radius: 15px;
}

.imgContainer > img {
  border-radius: 15px;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.extraContentContainer {
  display: flex;
  gap: 10px;
  flex-direction: row;
  margin-top: 35px;
  margin-bottom: 15px;
}

.contentContainer {
  margin: 5px 0;
  padding: 0 20px;
}

.one,
.two,
.three {
  min-height: 80px;
  width: 80px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50%;
  margin-bottom: 20px;
}

.contentContainer {
  margin-top: 10px;
  font-size: 17px;
  max-width: 400px;
}

.one {
  background-image: url(../../assets/images/1.svg);
}

.two {
  background-image: url(../../assets/images/2.svg);
}

.three {
  background-image: url(../../assets/images/3.svg);
}
