.coachPlanExpireDateContainer {
  display: flex;
  flex-direction: column;
  width: fit-content;
  height: fit-content;
  padding-bottom: 20px;
}

.coachPlanExpireDate {
  margin-top: 5px;
}