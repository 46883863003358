.widgetsContainer {
  display: flex;
  justify-content: space-between;
  gap: 15px;
  margin-top: 20px;
  flex-direction: column;

  @media only screen and (min-width: 768px) {
    flex-direction: row;
  }
}

.buttonsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-top: 20px;
}

.sectionContainer {
  margin-top: 50px;
}
