* {
  margin: 0;
  padding: 0;
}

*:focus {
  outline: none;
}

.Toastify__toast-container--bottom-center {
  bottom: 10px;
}

.Toastify__toast {
  height: 20px;
  background: rgb(255, 255, 255, 0.8);
  backdrop-filter: blur(10px);
  border-radius: 15px;
  color: black;
}

/* SCROLL BAR */
/* Target WebKit-based browsers */

::-webkit-scrollbar {
  width: 15px;
}

::-webkit-scrollbar-track {
  background: none; /* color of the track */
}

::-webkit-scrollbar-thumb {
  background-color: var(--background-quaternary);
  border-radius: 100px;
  background-clip: content-box;
  border: 4px solid transparent;
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(
    --background-quaternary-hover
  ); /* color of the thumb on hover */
}

/* turn off scrollbar for mobile: */
@media only screen and (max-width: 600px) {
  html {
    scrollbar-width: none;
  } /* Firefox */

  body {
    -ms-overflow-style: none;
  } /* IE and Edge */

  body::-webkit-scrollbar,
  body::-webkit-scrollbar-button {
    display: none;
  } /* Chrome */
}
