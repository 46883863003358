.container {
  display: flex;
  border-radius: 10px;
  cursor: pointer;
  justify-content: space-between;
  padding: 10px;
  transition: 0.05s linear;
  gap: 20px;
  border: 1px solid transparent;
  @media only screen and (max-width: 450px) {
    border: 1px solid #333333;
    padding: 15px;
    padding-bottom: 20px;
  }
}

.container:hover {
  border: 1px solid transparent;
  background: var(--background-secondary-translucent);
}

.imageTitleContainer {
  display: flex;
  gap: 20px;
  @media only screen and (max-width: 450px) {
    flex-direction: column;
    gap: unset;
  }
}

/* .imageTitleContainerOpen {
  @media only screen and (max-width: 950px) {
    flex-direction: column;
  }
} */

.image {
  border: 1px solid #222222; /* to be deleted once thumbnails are fixed */
  min-height: 66px;
  height: 10vw;
  max-height: 100px;
  width: auto;
  max-width: min-content;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.1s linear;

  /* &:hover {
    scale: 1.05;
    } */
}

.expandedImage {
  @media only screen and (min-width: 950px) {
    max-height: unset;
    height: 170px;
  }
}

.tagsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.tagContainer {
  display: flex;
  padding: 5px 15px;
  border: 1px solid var(--text-primary) !important;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.cardContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 15px;
}

.cardContentText {
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-top: 10px;
}

.rightContentContainer {
  display: flex;
  margin-top: 25px;
  /* align-items: center; */
  gap: 12px;
  min-width: max-content;
  height: min-content;
}

.playContent {
  display: none;

  @media only screen and (min-width: 768px) {
    height: min-content;
    display: flex;
    gap: 12px;
  }
}

.watchTimeContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.playIconContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  transition: 0.1s linear;

  &:hover {
    scale: 1.1;
  }
}

.expandIconContainer {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--background-secondary-translucent);
  width: 40px;
  height: 40px;
  border-radius: 20px;
  transition: 0.05s linear;

  &:hover {
    background: var(--background-tertiary-translucent);
  }
}

.arrowOpen {
  transform: rotate(270deg);
}

.arrowClosed {
  transform: rotate(90deg);
}

.lessOpacity {
  opacity: 0.2;
}

.shortDescription {
  display: block;
}
.medDescription {
  display: none;
}
.fullDescription {
  display: none;
}

@media only screen and (min-width: 850px) {
  .medDescription {
    display: block;
  }
  .shortDescription {
    display: none;
  }
  @media only screen and (min-width: 1100px) {
    .fullDescription {
      display: block;
    }
    .medDescription {
      display: none;
    }
  }
}
