.container {
  padding: 50px;
}

.manageReviewsContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.titleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.title {
  font-size: 30px;
  font-weight: 500;
}