.container {
  width: 30vw;
  min-width: 800px;
}

.topContainer {
  margin-top: 30px;
  display: flex;
  max-width: 400px;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
}

.userAvatarContainer {
  gap: 20px;
  display: flex;
  align-items: center;
}

.userAvatar {
  width: 60px;
  height: 60px;
}

.logoutButton {
  margin-left: 50px;
}

.formContainer {
  margin-top: 30px;
  max-width: 400px;
}

.inputContainer {
  margin-top: 20px;
}

.buttonContainer {
  display: flex;
  margin-top: 30px;
}
