:root {
  --formWidth: 300px;
}

body {
  background-color: var(--bgColor);
  position: relative;
  overflow: overlay;
  overflow-x: hidden;
  width: 100%;
  /* border: 1px solid blue; */
}

.page {
  margin: auto;
  max-width: 1000px;
  padding: 0px var(--pagePadding);
  display: flex;
  flex-direction: column;
}

.centerPage {
  margin: auto;
  max-width: 1000px;
  padding: 0px var(--pagePadding);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.formPage {
  width: 100%;
  min-height: calc(100vh - var(--pageTopMargin));
  background-image: url(../assets/images/red-wavy-bg.png);
  background-position: bottom center;
  background-size: cover;
}

.formPageContentContainer {
  padding: 20px var(--pagePadding) 50px var(--pagePadding);
  width: calc(100% calc(2 * var(--pagePadding)));
  margin: auto;
  max-width: var(--formWidth);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.formPageTitle {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

/* unused */
.redWavyBg {
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  background-image: url("https://geology.com/world/world-map.gif");
  /* background-image: url(../assets/images/red-wavy-bg.png); */
  background-size: contain;

  z-index: -10;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
}

.doubleCell {
  display: flex;
  gap: 10px;
}

.doubleCell > * {
  flex-grow: 1;
}

.aboutPage {
  margin: auto;
  max-width: 750px;
  padding: 0px var(--pagePadding);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  /* border: 1px solid red; */
}

.whiteGradient {
  /* width: max-content; */
  background-image: var(--whiteGradient);
  background-size: cover;

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}

.infocardsContainer {
  margin-top: 25px;
}

@media only screen and (max-width: 550px) {
  .iconcards {
    display: none;
  }
  .iconcardsMED {
    display: none;
  }
  .iconcardsSMALL {
    margin-top: var(--cardSpacing);
    gap: var(--cardSpacing);
    display: flex;
    justify-content: center;
  }
  .iconcardsCol {
    /* border: 1px solid blue; */
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: var(--cardSpacing);
  }
}
@media only screen and (min-width: 551px) {
  .iconcards {
    display: none;
  }
  .iconcardsMED {
    margin-top: var(--cardSpacing);
    gap: var(--cardSpacing);
    display: flex;
    justify-content: center;
  }
  .iconcardsSMALL {
    display: none;
  }

  .iconcardsCol {
    /* border: 1px solid blue; */
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: var(--cardSpacing);
  }
}
@media only screen and (min-width: 850px) {
  .iconcards {
    margin-top: var(--cardSpacing);
    gap: var(--cardSpacing);
    display: flex;
    justify-content: center;
    /* border: 1px solid red; */
  }

  .iconcardsMED {
    display: none;
  }
  .iconcardsSMALL {
    display: none;
  }
}

.productcards {
  margin-top: var(--bigCardSpacing);
  gap: var(--bigCardSpacing);
  display: flex;
  justify-content: center;
}

.imagecard {
  margin-top: var(--smallSectionSpacing);
  width: 100%;
  height: 350px;
  overflow: hidden;
  position: relative;
  border-radius: 15px;
  background-image: url("../assets/images/lineup.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  /* background-position: 0px -50px; */
}

.imagecardContentContainer {
  padding: 0 var(--pagePadding);
  width: calc(100% - calc(2 * var(--pagePadding)) - 2px);
  height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: all 0.2s ease-in-out;
  &:hover .imagecardTitleContainer {
    scale: 1.05;
  }
}

.imagecardTitleContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 20px;
  transition: all 0.2s ease-in-out;
  flex-wrap: wrap;
}

.imagecardsTitle {
  width: min-content;
  font-size: clamp(16px, calc(25px + 5vw), 80px);
  /* font-size: 30px; */
  font-weight: 600;
  margin-bottom: 10px;
}

.imageCardButtons {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: var(--cardSpacing);
  margin-top: 35px;
}

.auditCardsContainer {
  display: flex;
  flex-direction: column;
  gap: var(--cardSpacing);
  max-width: 418px;
  /* border: 1px solid red; */
}

.reviewDocGridContainer {
  display: flex;
  gap: var(--bigCardSpacing);
  margin-top: -20px;
}

.reviewDoc {
  width: 100%;
  height: auto;
  border-radius: var(--cornerRadius);
  object-fit: cover;
}

.reviewCarousel {
  width: 100%;
  max-width: 475px;
  position: relative;
}

@media only screen and (max-width: 890px) {
  .reviewDocGridContainer {
    max-width: 475px;
  }

  .auditCardsContainer {
    display: none;
  }
}
@media only screen and (min-width: 891px) {
  .reviewDoc {
    min-width: 475px;
  }

  .reviewCarousel {
    display: none;
  }
}

.spacer {
  margin-top: 80px;
}

.bigSpacer {
  margin-top: 200px;
}

.flex10 {
  display: flex;
  justify-content: center;
  gap: 10px;
  width: 100%;
  max-width: 400px;
}

.horizontalForm {
  display: flex;
  justify-content: center;
  gap: 10px;
  width: 100%;
  max-width: 400px;
}

.horizontalForm div:nth-of-type(1) {
  flex-grow: 1;
}

.flex {
  display: flex;
  gap: var(--cardSpacing);
  flex-wrap: wrap;
  @media (max-width: 600px) {
    place-content: center;
  }
}

.olContainer {
  margin-left: 18px;
}

.centerColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--bigCardSpacing);
  max-width: 100%;
}

.centerCardBg {
  background: var(--cardBg);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--titleSpacer);
  padding: 30px var(--pagePadding) 40px var(--pagePadding);
  text-align: center;
  border-radius: 15px;
  gap: 15px;
  /* width: 100%; */
  /* margin-bottom: var(--cardSpacing); */
}

.centerCardBg > * {
  max-width: 36em;
}

.centerCard {
  /* background: var(--cardBg); */
  box-shadow: inset 0px 0px 0px 1px var(--darkGrey);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--titleSpacer);
  padding: 40px var(--pagePadding);
  text-align: center;
  border-radius: 15px;
  gap: 15px;
  margin-bottom: var(--cardSpacing);
}

.centerCardContent {
  max-width: 600px;
  padding: 0px 30px;
}

.centerDescription {
  max-width: 35em;
  text-align: center;
  margin-top: -10px;
  color: var(--darkText);
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.smallSectionContainer {
  margin-top: var(--smallSectionSpacing);
}

.titleContainer {
  margin-bottom: var(--bigCardSpacing);
}

.centerTitleContainer {
  text-align: center;
  margin-top: var(--smallSectionSpacing);
  margin-bottom: var(--bigCardSpacing);
}

.getStarted {
  width: min-content;
}

.pageTitleContainer {
  margin-bottom: 25px;
}

.infocardsPairContainer {
  width: 100%;
  max-width: calc(322.66px + 322.66px + var(--cardSpacing) + 1px);
}

@media only screen and (max-width: 640px) {
  .infocards {
    margin-bottom: var(--cardSpacing);
    gap: var(--cardSpacing);
    width: 100%;
    display: flex;

    flex-direction: column;
    justify-content: center;
  }

  .carouselInfocards {
    display: none;
  }

  .infocardsPairContainer {
    max-width: 420px;
  }
}

@media only screen and (min-width: 640px) {
  .infocards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(263.33px, 1fr));
    justify-content: center;
    justify-items: stretch;
    gap: var(--cardSpacing);
  }

  .carouselInfocards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(263.33px, 1fr));
    justify-content: center;
    justify-items: stretch;
    gap: var(--cardSpacing);
  }

  .carousel {
    display: none;
  }
}

.sectionContainer {
  margin-top: var(--sectionSpacing);
}

.sectionContainer {
  &:hover .headlineContainer {
    transform: scale(1.05);
  }
}

.headlineContainer {
  transition: 0.5s;
}

.videoContainerContainer {
  max-width: 700px;
  width: 100%;
}

.videoContainer {
  width: 100%;
  position: relative;
  padding-top: 56.25%;
}

.video {
  border-radius: 15px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.redGradient {
  height: 800px;
  width: 800px;
  position: absolute;
  /* transform: rotate(90deg);
  left: 0; */
  right: 0;
  bottom: 150px;
  opacity: 35%;
  background: radial-gradient(
    circle at right bottom,
    rgba(181, 12, 12, 1) 0%,
    rgba(181, 12, 12, 0) 70%
  );
  z-index: -10;
}

.topRedGradient {
  height: 800px;
  width: 800px;
  max-width: 100%;
  position: absolute;
  left: 0;
  top: 0px;
  opacity: 35%;
  background: radial-gradient(
    circle at left top,
    rgba(181, 12, 12, 1) 0%,
    rgba(181, 12, 12, 0) 70%
  );
  z-index: -10;
}

.topCenterRedGradient {
  height: 1000px;
  width: 99%;
  max-width: 2000px;
  position: absolute;
  top: -370px;
  opacity: 45%;
  background: radial-gradient(
    circle at center top,
    rgba(181, 12, 12, 1) 0%,
    rgba(181, 12, 12, 0) 70%
  );
  z-index: -10;
}

@keyframes slide {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

@media only screen and (max-width: 640px) {
  .testimonials {
    display: none;
  }
}

@media only screen and (min-width: 640px) {
  .testimonialCarousel {
    display: none;
  }
  .testimonials {
    display: flex;
    flex-wrap: nowrap;
    width: 120%;
    margin-left: -10%;
    margin-top: 10px;
    overflow: hidden;
    mask: linear-gradient(
      to right,
      rgba(0, 0, 0, 0) 1px,
      rgba(0, 0, 0, 1) 85px,
      rgba(0, 0, 0, 1) calc(100% - 85px),
      rgba(0, 0, 0, 0) calc(100% - 1px)
    );
  }
}

.testimonials:hover .testimonialsSlide {
  animation-play-state: paused;
}

.testimonialsSlide {
  display: flex;
  animation: 20s slide infinite linear;
}

.verticalTestimonialCarousel {
  margin-top: 50px;
}

/*
// width
::-webkit-scrollbar {
  width: 18px;
  height: 18px;
}
// track
::-webkit-scrollbar-track {
  margin-left: -10px;
  background: #aaaaaa;
}
// handle
::-webkit-scrollbar-thumb {
  border-radius: 100px;
  box-shadow: inset 0px 0px 0px 1px #7c7c7c;
  border: 4px solid transparent;
}
// handle on hover
::-webkit-scrollbar-thumb:hover {
  box-shadow: inset 0px 0px 0px 10px #70707070;
  border: 4px solid transparent;
}
*/
