:root {
  --bgColor: #080808;
  --cornerRadius: 15px;
  --bigCardSpacing: 25px;
  --medCardSpacing: 15px;
  --cardSpacing: 15px;
  --sectionSpacing: 130px;
  /* --pageTopSpacing: 110px; */
  --pageTopMargin: 130px;
  --smallSectionSpacing: 90px;
  --insetPadding: 40px;
  --darkGrey: #505050;
  --athleteRed: #b50c0c;
  --redGradient: linear-gradient(90deg, #000 -154.71%, #b50c0c 37.5%);
  --whiteGradient: linear-gradient(90deg, white 70%, transparent 160%);
  --cardBg: rgba(255, 255, 255, 0.1);
  --solidCardBg: #212121;
  --formBg: rgba(255, 255, 255, 0.1);
  --darkText: #707070;
  --pagePadding: 2em;
}

/* @media only screen and (max-width: 660px) {
  :root {
    --cardBg: rgba(255, 255, 255, 0.15);
  }
}
@media only screen and (min-width: 660.1px) {
  :root {
    --cardBg: rgba(255, 255, 255, 0.1);
  }
} */

a {
  color: var(--darkText);
  font-family: Inter, sans-serif;
  cursor: pointer;
}

.headerContent {
  position: fixed;
  z-index: 100;
  width: 100%;
  max-width: 100% !important;
}

.page {
  padding-top: var(--pageTopMargin);
}

.pageExtraPadding {
  padding-top: calc(var(--pageTopMargin) + 40px);
}
