.container {
  display: flex;
  height: 100vh;
  max-height: 100vh;
  overflow: unset;
  flex-direction: column;

  @media only screen and (min-width: 768px) {
    flex-direction: row;
  }
}

.navContainer {
  width: var(--nav-bar-size);
  min-width: var(--nav-bar-size);
  display: none;

  @media only screen and (min-width: 768px) {
    display: block;
  }
}

.navContainerClosed {
  min-width: unset;
  width: var(--nav-bar-closed-size);
}

.toggleContainer {
  position: relative;
}

.contentContainer {
  width: calc(100vw - var(--nav-bar-size));
  background: linear-gradient(
    45deg,
    rgba(0, 0, 0, 1) 68%,
    rgba(41, 10, 10, 1) 100%
  );
  overflow-y: scroll;
  min-height: 100vh;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
}

.contentContainerClosedNav {
  width: calc(100vw - var(--nav-bar-closed-size));
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
}
