.container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.headerContainer {
  display: flex;
  align-items: center;
  gap: 20px
}

.seeAllButton {
  border-radius: 100px !important;
}

.listContainer {
  display: flex;
  overflow-x: scroll;
  gap: 20px;
}