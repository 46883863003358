.currentPlanContainer {
  margin-top: 30px;
}

.planCardContainer {
  display: flex;
  gap: 20px;
  margin-top: 15px;
  flex-wrap: wrap;

  @media only screen and (min-width: 768px) {
    flex-wrap: nowrap;
    flex-direction: row;
  }
}

.otherPlansContainer {
  margin-top: 30px;
}

.coachPlanContainer {
  display: flex;
  margin-top: 30px;
  gap: 20px;
}

.paymentsContainer {
  display: flex;
  border-top: 1px solid var(--border-secondary);
  margin-top: 60px;
  padding-top: 20px;
}

.reviewsLeftContainer {
  height: fit-content;
  padding: 20px;
}
