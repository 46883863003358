.container {
  padding: 24px;
  border-radius: var(--cornerRadius);
  /* width: 370px; */
  box-shadow: inset 0px 0px 0px 1px var(--darkGrey);
}

.contentContainer {
  margin-top: 14px;
  font-size: 15px;
}
