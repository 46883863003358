.container {
  /* position: absolute;
  right: 1vw;
  top: 270px;
  z-index: 100; */
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 9px 0;
  width: 100%;
  height: calc(50px - 18px);

  @media (min-width: 1000px) {
    height: unset;
    border-radius: var(--borderRadFilters) 0 0 var(--borderRadFilters);
    right: 1vw;
    top: 120px;
  }

  @media (min-width: 1700px) {
    right: 2.5vw;
  }
}

.sunIcon {
  height: 23px;
  width: 23px;
}

.moonIcon {
  height: 20px;
  width: 20px;
  padding: 1.5px;
}
