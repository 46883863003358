.container {
  font-size: 22px;
  font-weight: 600;
}

.plans {
  font-size: 23px;
  font-weight: 500;
}

.plansDark {
  font-weight: 500;
  /* color: var(--darkGrey); */
  color: #707070;
}
