.container {
  position: absolute;
  top: 140px;
  left: 10px;
  width: 200px;
  max-width: 300px;
  z-index: 100;
  padding: 15px;
  border-radius: 10px;

  @media (min-width: 768px) {
    top: 80px;

    @media (min-width: 1000px) {
      left: unset;
      top: 45px;
      right: 89px;
      width: 190px;

      @media (min-width: 1300px) {
        top: 0;
        right: 89px;

        @media (min-width: 1700px) {
          width: 250px;
        }
      }
    }
  }
}

.isDemo {
  @media (max-width: 1000px) {
    top: 120px;
  }
}

.headerContainer {
  display: flex;
  justify-content: space-between;
}

.title {
  font-weight: bold;
  margin-bottom: 12px;
  font-size: 14px;

  @media (min-width: 1000px) {
    font-size: 14px;
  }
}

.close {
  cursor: pointer;
  font-weight: bold;
}

.info {
  user-select: text;
  font-size: 14px;
}

.infoLarge {
  user-select: text;
  font-size: 18px;
  font-weight: 600;
}

.addressLine {
  border-radius: 6px;
  padding: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  margin-bottom: 14px;
}

.copyContainer {
  cursor: pointer;
  min-width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
}

.copyContainer:hover {
  background: rgba(255, 255, 255, 0.1);
}

.copyContainerDark:hover {
  background: rgba(0, 0, 0, 0.1);
}

.distanceDurationContainer {
  margin-top: 10px;
  display: flex;
  gap: 15px;
  margin-bottom: 8px;
}
