.container {
  padding: 50px;
}

.uploadedFilesContainer {
  display: flex;
  gap: 80px;
  margin-bottom: 50px;
}

.uploadedFileTitle {
  font-weight: bold;
  font-size: 20px;
}

.dragContainer {
  border: 3px dashed #CCC;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 20px;
  width: 500px;
  display: flex;
  justify-content: center;
}

.hoverDragContainer {
  border: 3px dotted #CCC;
}

.fileToUploadContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.fileToUploadButtonContainer {
  display: flex;
  margin-top: 20px;
}