/* :root {
  --topPadding: 25px;
  --columnTopPadding: 25px;
  --listTopMargin: 30px;
} */

.centerColumn {
  /* border: 1px solid red; */
  margin-top: var(--pageTopSpacing);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.headlineContainer {
  margin-top: 70px;
}
@media only screen and (max-width: 610px) {
  .iconcards {
    margin-top: 40px;
    gap: var(--cardSpacing);
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
@media only screen and (min-width: 610.1px) {
  .iconcards {
    margin-top: 30px;
    gap: var(--cardSpacing);
    display: flex;
    justify-content: center;
  }
}

.descriptionContainer {
  margin: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.pricingItem {
  margin-top: 14px;
}

.listContainer {
  text-align: left;
  margin-left: 18px;
}

ul {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* .cardsContainerContainer {
  border: 1px solid red;
  display: flex;
} */

.cardsContainer {
  gap: var(--cardSpacing);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

@media only screen and (max-width: 890px) {
}

.emailIcon {
  width: 18px;
}
