.modalContainer {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  height: 100%;
  width: 100%;
  backdrop-filter: blur(50px);
  z-index: 100;

  @media only screen and (min-width: 768px) {
    width: calc(100vw - var(--nav-bar-closed-size));
  }
}

.darkBackground {
  background-color: #00000077;
}

.modalContainerClosed {
  @media only screen and (min-width: 768px) {
    width: calc(100vw - var(--nav-bar-closed-size));
  }
}

.restrictedTitle {
  font-weight: 600;
  font-size: 22px;
  text-align: center;
  padding: 0 20px;
}

.upgradeButtonContainer {
  padding: 9px 32px !important;
  border-radius: 200px;
  margin: 0 20px;
  transition: 0.1s linear;
}

.upgradeButtonContainer:hover {
  transform: scale(1.03);
}

.upgradeButton {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.upgradeText {
  font-size: 15px;
}
