.content {
  margin-top: 25px;
}

.cardsContainer {
  display: grid;
  gap: 15px;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
}

.title {
  margin-left: 20px;
}
