:root {
  --padding: 30px;
}

@media only screen and (max-width: 640px) {
  .container {
    padding: var(--padding);
    padding-bottom: calc(var(--padding) + 5px);
    border-radius: 15px;
    box-shadow: inset 0px 0px 0px 1px var(--darkGrey);
    display: flex;
    flex-direction: column;
    gap: 15px;
    text-align: center;
    max-width: calc(100% - calc(2 * var(--padding)));
  }
}
@media only screen and (min-width: 641px) {
  .container {
    padding: var(--padding);
    padding-bottom: calc(var(--padding) + 5px);
    border-radius: 15px;
    box-shadow: inset 0px 0px 0px 1px var(--darkGrey);
    display: flex;
    gap: var(--padding);
    text-align: left;
    max-width: calc(100% - calc(2 * var(--padding)));
  }
}

.contentContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 5px;
}

.leftContainer {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
}

.headshot {
  border-radius: 100%;
  width: 130px;
  height: 130px;
}

.socialsContainer {
  display: flex;
  gap: 10px;
}

.socials {
  height: 20px;
}
