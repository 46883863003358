.container {
  z-index: 200;
  height: 50px;
  max-height: 50px;
  width: calc(100% - 20px);
  max-width: calc(100vw - 140px);

  @media (min-width: 1000px) {
    height: unset;
    width: 42vw;
  }

  @media (min-width: 1700px) {
    width: 35vw;
    max-width: 700px;
  }
}

.contentContainer {
  position: relative;
  height: 100%;
}

.searchIcon {
  position: absolute;
  right: 13px;
  top: 13px;

  @media (max-width: 1000px) {
    right: 16.5px;
    top: 16.5px;
  }
}

.input {
  width: -webkit-fill-available;
  height: 100%;
  padding: 0 16px;
  background-color: var(--background-tertiary);
  border-radius: 10px;
  border: none;
  color: #fff;
  font-size: 15px;
  box-shadow: 0px 4px 3px -1px rgb(0, 0, 0, 0.2);

  @media (min-width: 1000px) {
    border-radius: 0 0 var(--borderRadFilters) 0;
  }
}

.inputDark {
  background-color: var(--background-tertiary-light);
  color: #000;
  box-shadow: 0px 4px 5px -1px rgb(0, 0, 0, 0.6);
}

.inputDark::placeholder {
  color: #575757;
}

.inputDark::-ms-input-placeholder {
  color: #575757;
}

.inputDark:-ms-input-placeholder {
  color: #575757;
}

.schoolName {
  padding: 20px;
  border-radius: 15px;
  cursor: pointer;
  font-size: 12px;
  transition: 0.05s linear;

  @media (min-width: 1000px) {
    font-size: 14px;
  }
}

.schoolNameDark {
  color: #fff;
}

.schoolName:hover {
  background-color: var(--background-tertiary-light);
}

.schoolNameDark:hover {
  background-color: var(--background-secondary);
}

.emptyContainer {
  height: 30px;
}

.schoolNameContainer {
  margin-top: -30px;
  background-color: var(--background-quaternary-light);
  border-radius: 15px;
}

.schoolNameContainerDark {
  margin-top: -30px;
  background-color: var(--background-tertiary);
  border-radius: 15px;
}

.scrollableContainer {
  overflow: scroll;
  max-height: 72vh;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.scrollableContainer::-webkit-scrollbar {
  display: none;
}
