.contentContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 75px;
}

.signUpContainer {
  display: flex;
  flex-direction: column;
  max-width: 50%;
  gap: 20px;
}

.cardsContainer {
  display: flex;
  gap: 10px;
  max-width: 50%;
}

.doubleCell {
  display: flex;
  gap: 10px;
}

.acceptMessageContainer {
  margin-top: 50px;
}

.acceptButtonsContainer {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 50px;
}