.container {
  display: flex;
  flex-direction: column;
}

.label {
  margin-left: 15px;
}

.input {
  background-color: #FFFFFF22;
  color: #FFF;
  border: none;
  border-radius: 8px;
  padding: 8px 15px;
  margin-top: 10px;
  flex: 1;
}

.input::placeholder {
  opacity: 1;
  color: #707070;
}
