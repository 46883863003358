.container {
  padding: 30px;
  padding-bottom: 40px;
  border-radius: var(--cornerRadius);
  /* WIDTH EQUATION: ((screensize - var(--cardSpacing) - var(--cardSpacing)) / 3) - (2 * var(--padding)) */
  max-width: 322.66px;
  background: var(--cardBg);
  position: relative;
}

.center {
  justify-content: center;
}

.center > * {
  /* border: 1px solid red; */
}

@media only screen and (max-width: 640px) {
  .container {
    min-width: calc(100% - calc(2 * var(--pagePadding)));
  }
}
@media only screen and (min-width: 641px) {
  .container {
    /* WIDTH EQUATION: ((screensize - var(--cardSpacing) - var(--cardSpacing)) / 3) - (2 * var(--padding)) */
    /* width: 263.33px; */
  }
}

.outline {
  background: none;
  box-shadow: inset 0px 0px 0px 1px var(--darkGrey);
}

.titleContainer {
}

.contentContainer {
  margin-top: 15px;
}

.darkText {
  color: var(--darkText);
}

.extraContentSpacer {
  margin-top: 60px;
}

.extraContentContainer {
  position: absolute;
  bottom: 35px;
  display: flex;
  gap: 10px;
  flex-direction: row;
  width: 100%;
}

.imageContainer {
  margin-top: 20px;
  margin-bottom: -10px;
  position: relative;
}

.image {
  width: 100%;
  border-radius: 8px;
}

.imageIconContainer {
  width: 80px;
  height: 80px;
  background: rgba(0, 0, 0, 0.452);
  backdrop-filter: blur(10px);
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 0 7px 0 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.imageIcon {
  width: 36px;
}

.setCursor {
  cursor: pointer;
}
