.container {
}

.submissionButtonContainer {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}

.titleContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.titleIcon {
  display: block;

  @media only screen and (min-width: 768px) {
    display: none;
  }
}
