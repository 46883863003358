.container {
  display: flex;
  height: 100vh;
  max-height: 100vh;
  overflow: hidden;
}

.navContainer {
  width: 200px;
  background-color: #202020;
}

.logoContainer {
  position: relative;
  height: 25%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.fullLogo {
  width: 120px;
}

.adminMenuContainer {
  height: 75%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.contentContainer {
  width: calc(100vw - 200px);
  background-color: #0d0d0d;
  overflow: scroll;
}
