.container {
  height: fit-content;
  max-width: fit-content;
  /* max-width: 220px; */
}

.contentContainer {
  margin-top: -10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: max-content;
  @media only screen and (max-width: 768px) {
    margin-right: 25px;
  }
}

.dateContainer {
  margin-top: 5px;
}

.cancelBtn {
  cursor: pointer;
  background: var(--background-secondary-translucent);
  padding: 6px 14px;
  border-radius: 100px;
  text-wrap: wrap;
}
