.container {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 8px;
  border: 1px solid transparent;
  &:hover{
    border: 1px solid #FFF;
    border-radius: 10px;
  }
}

.imageContainer {
  overflow: hidden;
  border-radius: 6px;
}

.image {
  width: 150px;
  height: 99px;
  object-fit: contain;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}