.container {
  display: flex;
  justify-content: space-between;
  width: 30vw;
  min-width: 500px;
  cursor: pointer;
}

.labelContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}