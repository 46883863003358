.container {
  padding: 40px;
}

.titleContainer {
  display: flex;
  justify-content: space-between;
}

.title {
  font-size: 35px;
}

.userInfoContainer {
  margin-top: 20px;
}

.userTitle {
  font-size: 25px;
  margin-top: 20px;
}

.reviewInfoContainer {
  margin-top: 20px;
}

.reviewersContainer {
  margin-top: 20px;
}