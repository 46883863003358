.container {
  border-radius: 15px;
  width: 100%;
  height: 316.66px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.buttonContainer {
  margin: 25px;
}

.database {
  background-image: url("../../assets/images/database-header.png");
  background-size: cover;
  size: 150%;
}

.review {
  background-image: url("../../assets//images/hero-filmReview.png");
  background-size: cover;
}

.vault {
  background-image: url("../../assets/images/hero-vault.png");
  background-size: cover;
  height: 30vw;
  max-height: 316.66px;
}
