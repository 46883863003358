:root {
  /* text colors */
  --text-primary: #fff;
  --text-secondary: #777; /* to be deleted */

  --dark-accent-primary: #505050;
  --dark-accent-secondary: #707070;
  --dark-accent-tertiary: #909090;

  /* text sizes */
  --description: 12px;
  --regular: 15px;
  --title: 32px;
  --h1: 26px;
  --h2: 22px;
  --h3: 18px;

  /* background colors */
  --background-primary: #080808;
  --background-secondary: #202020;
  --background-secondary-translucent: rgba(255, 255, 255, 0.1);
  --background-tertiary: #3a3a3a;
  --background-tertiary-translucent: rgba(255, 255, 255, 0.205);
  --background-quaternary: #606060;

  --background-tertiary-light: #cbd0d3;
  --background-quaternary-light: #e9ecf0;

  /* background hover */
  --background-primary-hover: var(--background-secondary);
  --background-primary-translucent-hover: var(
    --background-secondary-translucent
  );
  --background-secondary-hover: var(--background-tertiary);
  --background-secondary-translucent-hover: rgba(255, 255, 255, 0.15);
  --background-tertiary-hover: var(--background-quaternary);
  --background-quaternary-hover: #808080;

  /* borders */
  --border-primary: #fff;
  --border-secondary: #777;
  --border-tertiary: #fff;

  /* border-radius */
  /* --border-radius-primary: 20px;
  --border-radius-secondary: 15px; */

  /* colors */
  --white: #fff;
  --black: #000;
  --red: #b50c0c;
  --red-hover: #b50c0cbb;

  /* sizes */
  --nav-bar-size: 200px;
  --nav-bar-closed-size: 80px;
}
