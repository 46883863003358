.container {
  display: flex;
  position: fixed;
  user-select: none;
  touch-action: none;
  width: 100%;
  z-index: 999;

  @media only screen and (min-width: 768px) {
    display: none;
  }
}

.logo {
  width: 120px;
  height: auto;
}

.iconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.headerContainer {
  position: absolute;
  display: flex;
  padding: 10px 25px;
  padding-bottom: 20px;
  box-sizing: border-box;
  justify-content: space-between;
  width: 100%;
  z-index: 999;
}

.database {
  background-color: var(--background-secondary);
  padding: 0;
  padding: 0 10px;
  height: 60px;
  align-items: center;
  & .logo {
    height: 55px;
    width: auto;
  }
}

/* .headerContainerOpenMenu {
  background-color: var(--background-tertiary);
  } */

.scrolled {
  background-image: linear-gradient(
    #0d0d0d 20%,
    #0d0d0db0 60%,
    #0d0d0d15 90%,
    #0d0d0d00
  );
}

.menuContainer {
  position: absolute;
  top: 70px;
  right: 25px;
  height: calc(90vh - 70px);
  width: 200px;
  max-height: 500px;
  z-index: 999;
}

.menuButtonContainer {
  display: flex;
  align-items: center;
}
.menuButton {
  background: rgba(255, 225, 225, 0.2);
  backdrop-filter: blur(4px);
  cursor: pointer;
  user-select: none;
  border-radius: 100px;
  padding: 11px 18px;
}

.menuButtonContent {
  display: flex;
  max-height: min-content;
  align-items: center;
  gap: 8px;
  font-weight: 600;
  font-size: 12.5px;
}

#icon {
  width: 15px;
  height: 8px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

#icon span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: white;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

#icon span:nth-child(1) {
  top: 0;
  -webkit-transform-origin: center;
  -moz-transform-origin: center;
  -o-transform-origin: center;
  transform-origin: center;
}

#icon span:nth-child(2) {
  bottom: 0;
  -webkit-transform-origin: center;
  -moz-transform-origin: center;
  -o-transform-origin: center;
  transform-origin: center;
}

#icon.opened span:nth-child(1) {
  width: 15px;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: translate(0px, 3px) rotate(45deg);
}

#icon.opened span:nth-child(2) {
  width: 15px;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: translate(0px, -3px) rotate(-45deg);
}
