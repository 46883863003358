.cardContainer {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  top: 0px;
  margin-bottom: 150px;
  /* border: 1px solid red; */

  .card {
    min-width: 100%;
    max-height: 800px;
    display: flex;
    flex-direction: column;
    position: relative;
    top: -25%;
    transform-origin: top;
  }
}
