.container {
  padding: 40px;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.close {
  font-size: 24px;
  font-weight: bold;
}

.title {
  font-size: 24px;
  font-weight: bold;
}

.questionInput {
  font-size: 18px;
  padding: 15px;
  width: 20vw;
}

.saveButtonContainer {
  display: flex;
  margin-top: 50px;
}

.ratesContainer {
  display: flex;
  gap: 20px;
  margin-top: 10px;
}

.rateContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 10vw;
}

.rate {
  font-size: 18px;
}

.equalContainer {
  display: flex;
  gap: 30px;
  margin-top: 20px;
}

.equalToInput {
  width: 200px;
}

.equalToNotes {
  margin-top: 10px;
  width: 100%;
  height: 80px;
}

.blueQuestionContainer {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 40px;
}
