.container {
  width: 350px;
  @media only screen and (max-width: 768px) {
    width: 275px;
  }
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.inputContainer {
  margin-top: 20px;
}

.bottomContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
}

.buttonContainer {
  padding: 10px 35px;
}
