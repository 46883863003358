.quickInfo {
  color: #fff;
  margin-top: 15px;
  font-size: 14px;

  @media (min-width: 1200px) {
    font-size: 20px;
  }
}

.coachInfo {
  color: #f00;
  font-size: 14px;

  @media (min-width: 1200px) {
    font-size: 20px;
  }
}

.notAvail {
  color: rgb(119, 119, 119);
}

.clickableCoach {
  cursor: pointer;
}

.demo {
  filter: blur(5px);
  margin-left: 6px;
  user-select: none;
}
