.container {
  display: block;

  @media (min-width: 1000px) {
    display: none;
  }
}

.contentContainer {
  width: calc(100% - 20px);
  max-width: 600px;
  left: 10px;
  height: calc(100vh - 160px);
  @media (max-width: 768px) {
    height: calc(100vh - 260px);
  }
  position: absolute;
  z-index: 300;
}

.content {
  position: relative;
}

.handleContainer {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: start;
  position: absolute;
  border-top-right-radius: 30px;
  border-top-left-radius: 30px;
  top: 0;
  left: 0;
  width: 100%;
  content: "";
  height: 120px;
  background-color: transparent;
  z-index: 999;
  padding-top: 12px;
  transition: 0.5s;
  -webkit-tap-highlight-color: transparent;
}

.handleContainerClosed {
  padding-bottom: 3px;
}
