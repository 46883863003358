.usersInPlanContainer {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}

.inviteUserContainer {
  display: flex;
  gap: 10px;
  align-items: center;
}

.usersListContainer {
  margin-top: 15px;
}

.userListHeaderContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.userListColumn {
  flex: 1;
}

.userListColumnCenter {
  display: flex;
  justify-content: center;
  flex: 1;
}

.userCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
  background-color: var(--background-tertiary-transparent);
  padding: 10px;
  border-radius: 10px;
}

.removeContainer {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}