.container {
  margin-top: 20px;
  background-color: #202020;
  padding: 15px;
  border-radius: 10px;
  cursor: pointer;;
}

.mainContent {
  display: flex;
}

.content {
  display: flex;
  flex-direction: column;
}

.image {
  width: 250px;
  min-width: 250px;
  height: 166px;
  margin-right: 30px;
  object-fit: contain;
}

.titleContainer {
  display: flex;
  gap: 20px;
  align-items: center;
}

.title {
  font-size: 16px;
}

.sendButtonContainer {
  margin-top: 20px;
  display: flex;
}

.deleteButtonContainer {
  margin-top: 20px;
  display: flex;
}