.buttonContainer {
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 100px;
  background-color: var(--background-tertiary);
  display: flex;
  justify-content: center;
  align-items: center;
  top: 25px;
  right: -15px;
  z-index: 999;
  cursor: pointer;
  box-shadow: 0 0 5px 0 #00000069;
}

.open {
  transform: rotate(180deg);
}

.closed {
  transform: rotate(0deg);
}
