.sectionsContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.section {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.card {
  display: flex;
  flex-direction: column;
  gap: 8px;
  /* background-color: var(--background-tertiary); */
  border: 1px solid grey;
  border-radius: 15px;
  padding: 20px;
  max-width: 400px;
}
