.buttonContainer {
  display: block;
  cursor: pointer;
}

.container {
  display: flex;
  gap: 15px;
  padding: 8px 18px;
  align-items: center;
  border-radius: 10px;
  transition: 0.05s linear;
  &:hover {
    background-color: var(--background-tertiary);
  }
}

.closedMenu {
  justify-content: center;
}

.mobileMenu {
  justify-content: unset;
}

.selectedMenu {
  background-color: var(--background-quaternary);
}

.icon {
  width: 23px;
  height: 23px;
}
