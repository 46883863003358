.headerContainer {
  display: flex;
  justify-content: space-between;
}

.input {
  width: 300px;
}

.videosContainer {
  margin-top: 40px;
}
