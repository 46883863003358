.container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.headerContainer {
  display: flex;
  align-items: center;
  gap: 20px;
}

.listContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
