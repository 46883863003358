.container {
  padding: 20px;
  border-radius: 15px;
  width: 500px;
  height: 700px;
  background-color: #333;
  overflow-y: scroll;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.closeButton {
  cursor: pointer;
}

.categoriesContainer {
  margin-top: 20px;
}

.category {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  margin-top: 5px;
  padding: 5px;
  border-radius: 5px;
  &:hover {
    background-color: #555;
  }
}