.container {
  padding: 40px;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.title {
  font-size: 24px;
  font-weight: bold;
}

.sectionsContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 20px;
  width: fit-content;
}

.sectionContainer {
  display: flex;
  align-items: center;
  gap: 20px;
  border: 1px solid #333333;
  padding: 10px 20px;
  border-radius: 8px;
}

.sectionTitle {
  font-size: 14px;
  min-width: 300px;
}

.activeContainer {
  display: flex;
  align-items: center;
  gap: 5px;
}

.buttonContainer {
  display: flex;
  margin-top: 30px;
}
