.label {
  font-weight: normal;
  cursor: pointer;
  width: fit-content;
  padding: 0 2px;
  display: flex;
  gap: 12px;
  align-items: center;
}

.label:hover {
  font-weight: 600;
}

.selectedLabel {
  font-weight: 600;
  background-color: white;
  padding: 0 8px;
  border-radius: 20px;
  color: #000;
  margin: 2px 0;
}

.selectedLabelDark {
  background-color: black;
  color: #fff;
}
