:root {
  --padding: 30px;
}

@media only screen and (min-width: 640px) {
  .container {
    width: 300px;
    margin-left: var(--cardSpacing);
  }
}

.container {
  padding: var(--padding);
  padding-bottom: calc(var(--padding) + 10px);
  border-radius: 15px;
  box-shadow: inset 0px 0px 0px 1px var(--darkGrey);
}

.nameContainer {
  display: flex;
  gap: 15px;
  align-items: center;
}

.contentContainer {
  margin-top: 20px;
  white-space: wrap;
  font-size: 18px;
  line-height: 130%;
}

.name {
  font-weight: 600;
  font-size: 20px;
  color: var(--darkText);
}

.profileIcon {
  width: 30px;
  height: 30px;
  fill: var(--darkGrey);
}
