.buttonsContent {
  display: flex;
  margin-top: 25px;
  gap: 15px;
}

.buttonContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-self: center;
  padding: 10px 10px;
  gap: 15px;
  height: 20px;
}

.reviewsContainer {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 15px;

  @media only screen and (min-width: 768px) {
    flex-direction: row;
  }
}

.reviewsContainer > * {
  max-width: 300px;
}

.infoContainer {
  margin-top: 30px;
  max-width: 500px;
}

.infoContentContainer {
  margin-top: 5px;
}

.newAuditButton {
  padding: 20px 30px !important;
  border-radius: 15px !important;
}
