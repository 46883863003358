.innerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--borderRadFilters) 0 0 var(--borderRadFilters);
  width: 100%;
  cursor: pointer;
}

.emptyContainer {
  margin-top: 20px;
  text-align: center;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  padding: 14px;
  padding-top: 12px;
}

.savedSchoolContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  border-bottom: 2px solid #000;
  padding-bottom: 5px;
}

.savedSchoolContainerDark {
  border-bottom: 2px solid #fff;
}

.schoolName {
  cursor: pointer;
}

.schoolName:hover {
  font-weight: 600;
}

.iconsContainer {
  display: flex;
  align-items: center;
}

.divider {
  height: 25px;
  width: 2px;
  margin: 0 5px;
}

.icon {
  cursor: pointer;
}
