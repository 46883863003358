.container {
  display: block;
  user-select: none;

  @media (min-width: 1000px) {
    display: none;
  }
}

.topMenu {
  position: absolute;
  display: flex;
  gap: 10px;
  z-index: 999;
  padding: 10px;
  width: calc(100% - 20px);
}

.schoolFilterContainer {
  padding: 0 20px;
  display: flex;
  gap: 10px;
  min-height: 50px;
}

.sideMenu {
  position: absolute;
  top: 150px;
  right: 10px;
  z-index: 100;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 50px;
}

.iconContainer {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  cursor: pointer;
}

/* .savedContainer {
  top: 215px;
  }
  
  .recentsContainer {
    top: 265px;
    }
    
    .previousContainer {
      top: 315px;
      } */

.popup {
  position: absolute;
  top: 150px;
  right: 70px;
  z-index: 100;
  width: calc(100% - 80px);
}

.extraPopupContainer {
  margin-left: 10px;
  width: 50%;
}

.notes {
  width: 100%;
  height: 90%;
}
