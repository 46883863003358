.loadingContainer {
  margin: 40px 50px;
  display: flex;
  align-items: center;
  gap: 12px;

  @media only screen and (max-width: 768px) {
    margin-top: 100px;
  }
}
