.baseCardContainer {
  justify-content: space-between;
}

.filmReviewItemContainer:not(:first-child) {
  margin-top: 5px;
}

.titleContainer {
  display: flex;
  align-items: center;
  gap: 10px;
  @media only screen and (max-width: 420px) {
    flex-direction: column;
    align-items: unset;
  }
}

.titleIcon {
  display: block;

  @media only screen and (min-width: 768px) {
    display: none;
  }
}

.buttonContainer {
  margin-top: 10px;
  display: none;
  align-items: center;
  gap: 10px;

  @media only screen and (min-width: 768px) {
    display: flex;
  }
}
