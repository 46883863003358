.titleContainer {
  margin-bottom: 10px;
}

.reviewsListContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  
  @media only screen and (min-width: 768px) {
    flex-direction: row;
  }
}

.buttonContainer {
  display: flex;
  margin-top: 20px;
}

.cardContainer {
  width: 100%;
  
  @media only screen and (min-width: 768px) {
    width: fit-content;
  }
}

.mainCardContainer {
  display: flex;
  padding: 30px 20px;
  
  @media only screen and (min-width: 768px) {
    padding: 0;
  }
}

.cardTitleContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.cardButtonContainer {
  cursor: pointer;
}

.viewText {
  color: var(--red);
  text-decoration: underline;
}

.detailsText {
  text-decoration: underline;
  color: var(--text-secondary);
}