:root {
  --borderRadFilters: 15px;
}

.container {
  display: none;
  user-select: none;

  @media (min-width: 1000px) {
    position: absolute;
    z-index: 999;
    display: block;
    width: 100%;
  }
}

.contentContainer {
  position: relative;
}

.topMenu {
  font-size: 15px;
  position: absolute;
  display: flex;
  gap: 12px;
}

.sideMenu {
  font-size: 12px;
  position: absolute;
  right: 0;
  top: 12px;
  display: flex;
  align-items: end;
  flex-direction: column;
  gap: 12px;
  width: min-content;
}

.logoContainer {
  position: absolute;
  top: 10px;
  right: 0vw;
  z-index: 100;
  cursor: pointer;

  @media (min-width: 1700px) {
    right: 2vw;
  }
}
