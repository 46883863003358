.container {
  width: 100%;
  user-select: none;
}

.contentContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-radius: var(--borderRadFilters) 0 0 var(--borderRadFilters);
  cursor: pointer;
  text-align: center;
  gap: 5px;
}

.emptyContainer {
  margin: 20px 0;
  text-align: center;
}

.savedSchoolContainer {
  display: flex;
  align-items: center;
  padding: 10px;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  border-radius: 8px;
  margin-bottom: 8px;
  transition: 0.1s linear;
  box-shadow: 0 0 0 1px #646464;
  cursor: pointer;
}
.savedSchoolContainer:hover {
  background-color: var(--background-quaternary);
  box-shadow: none;
}

.savedSchoolContainerDark {
  box-shadow: 0 0 0 1px #a8aaad;
}
.savedSchoolContainerDark:hover {
  background-color: var(--background-quaternary-light);
}

.textContainer {
  width: min-content;
}

.schoolName {
  font-size: 13px;
}

/* .schoolName:hover {
  font-weight: 500;
} */

.buttonsContainer {
  height: min-content;
  display: flex;
  gap: 5px;
  align-items: stretch;
}

.actionButton {
  color: white;
  font-weight: 300;
  padding: 6px 6px;
  display: flex;
  gap: 5px;
  align-items: center;
  border-radius: 6px;
  cursor: pointer;
}

.directionsButton {
  background-color: #536d81;
  &:hover {
    background-color: #3d5669;
  }
}
.notesButton {
  background-color: #8a7f64;
  &:hover {
    background-color: #635b47;
  }
}
.trashButton {
  background-color: #9c3f3f;
  &:hover {
    background-color: #803434;
  }
}

.icon {
  width: 15px;
  height: 15px;

  @media (min-width: 1700px) {
    width: 16px;
    height: 16px;
  }
}

.allPopupsContainer {
  display: flex;
  gap: 12px;
  position: absolute;
  right: 90px;
  top: 197px;
}

.popupContainer {
  width: 18vw;
  max-width: 330px;
  min-width: 250px;
}

.extraPopupContainer {
  width: 170px;
  min-width: 170px;

  @media (min-width: 1700px) {
    width: 180px;
    min-width: 180px;
  }
}

.notes {
  width: 100%;
  height: 90%;
}
