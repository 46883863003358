.title {
  font-size: 30px !important;
  font-weight: bold;
}

.tabsContainer {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  row-gap: 20px;
}

.tabTitle {
  cursor: pointer;
  text-wrap: nowrap;
  padding: 6px 13px;
  box-sizing: border-box;
  background-color: var(--background-secondary-translucent);
  border-radius: 100px;
  font-weight: 500;
}

.tabSelected {
  background-color: unset;
  background-color: white;
  color: black;
}

.infoContainer {
  padding: 20px;
  margin-top: 30px;
  border: 1px solid var(--border-secondary);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.infoContainerHeading {
  display: flex;
  align-items: center;
  gap: 7px;
}

.infoContainerText {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.tabContent {
  margin-top: 30px;
}
