.container {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.titleContainer {
  margin-bottom: 20px;
}

.cardsListContainer {
  overflow: auto;
  margin-bottom: 10px;
}

.buttonContainer {
  display: flex;
  align-self: flex-end;
  gap: 10px;
  margin-top: 20px;
}

.rightSideContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  
  @media only screen and (min-width: 768px) {
    width: 400px;
  }
}

.paymentMethodContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #555;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 10px;
  gap: 30px;
}

.paymentMethodButtonsContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}

delete {
  color: red;
}

.trashIcon {
  padding-top: 3px;
}
