.carousel {
  width: calc(100% + var(--pagePadding) + var(--pagePadding));
  transform: translateX(calc(-1 * (var(--pagePadding))));
  height: min-content;
  overflow: hidden;
  position: relative;
  mask: linear-gradient(
    to right,
    rgba(0, 0, 0, 0) 1px,
    rgba(0, 0, 0, 1) 15px,
    rgba(0, 0, 0, 1) calc(100% - 15px),
    rgba(0, 0, 0, 0) calc(100% - 1px)
  );
}

/* .carousel:before,
.carousel:after {
  position: absolute;
  top: 0;
  width: 20px;
  height: 100%;
  content: "";
  z-index: 2;
}

.carousel:before {
  left: 0;
  background: linear-gradient(90deg, var(--bgColor) 15%, transparent);
}

.carousel:after {
  right: 0;
  background: linear-gradient(270deg, var(--bgColor) 15%, transparent);
} */

.carouselContainer {
  width: calc(100% - var(--pagePadding) - var(--pagePadding));
  transform: translateX(var(--pagePadding));
  /* border: 1px solid red; */
}

.cardContainer {
  width: 100%;
  display: flex;
  cursor: grab;
  place-items: center;
}

.cardContainer:active {
  cursor: grabbing;
}

.card {
  transform: translateX(calc(-1 * (var(--pagePadding) - 17px)));
  width: 100%;
  height: min-content;
  flex-shrink: 0;
  border-radius: 15px;
  object-fit: cover;
}

.currentCard {
  padding: 0 calc(var(--pagePadding) - 17px);
}

.navContainer {
  width: calc(100% - var(--pagePadding) - var(--pagePadding) - 20px);
  padding: 0 calc(var(--pagePadding) + 10px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0;
}

.dotsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: min-content;
}

.dots {
  height: 9px;
  width: 9px;
  border-radius: 20px;
  background-color: var(--darkGrey);
  transition: 0.3s;
  cursor: pointer;
}
.dots:hover {
  scale: 130%;
}

.currentDot {
  background-color: white;
  scale: 125%;
}

.leftButton,
.rightButton {
  width: 20px;
  height: 20px;
  cursor: pointer;
  transition: 0.3s;
}
.leftButton {
  transform: scaleX(-1);
}

.leftButton:hover,
.rightButton:hover {
  scale: 120%;
}

.buttonDisabled {
  background-color: var(--darkGrey);
}
