.container {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.title {
  font-size: 35px;
}

.buttonContainer {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 15px;
}

.positionFeedbackTitle {
  font-size: 20px;
}

.questionsContainer {
  margin-top: 10px;
  margin-left: 30px;
}

.questionContainer {
  display: flex;
  align-items: center;
  padding: 5px 0px;
}

.questionTitleContainer {
  width: 20vw;
  min-width: 200px;
}

.blueQuestionContainer {
  border-radius: 10px;
  padding: 4px 10px;
  background-color: #007;
}

.blueQuestion {
  font-size: 10px;
}
