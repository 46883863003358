.container {
  display: flex;
  gap: 10px;
}

.ratesContainer {
  display: flex;
  gap: 10px
}

.rateContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100px;
}

.rateText {
  font-size: 9px;
}

.radio:disabled {
  opacity: 0.3;
}

.showNotesContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.notes {
  font-size: 9px;
}