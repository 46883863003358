.container {
  padding: 40px;
}

.title {
  font-size: 35px;
}

.section {
  margin-top: 15px;
  width: 33%;
  min-width: 400px;
}

.tagInputContainer {
  position: relative;
  display: flex;
  align-items: flex-end;
  gap: 20px;
}

.tagsContainer {
  display: flex;
  margin-top: 10px;
  gap: 10px;
}

.tag {
  display: flex;
  gap: 10px;
  padding: 5px;
  border: 1px solid #202020;
  border-radius: 5px;
}

.removeTagContainer {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.removeTag {
  font-size: 8px;
}

.tagsInputDropdown {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 5px;
  background-color: #202020;
  border-radius: 10px;
  padding: 10px;
}

.filteredTagContainer {
  cursor: pointer;
  min-width: 100px;
  border-radius: 10px;
  padding: 5px 10px;

  &:hover {
    background-color: #333;
  }
}

.imageContainer {
  display: flex;
  gap: 20px;
}

.image {
  width: auto;
  height: 100%;
  max-height: 500px;
}

.body {
  margin-left: 15px;
}

.bodyCardsContainer {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 30px;
  gap: 20px;
}

.bodyCardContainer {
  display: flex;
  flex-direction: column;
  padding: 12px;
  border: 1px solid #CCC;
  border-radius: 8px;
  gap: 8px;
}

.bodyCardTitle {
  font-size: 16px;
}

.bodyCardDescription {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.bodyCardButtonsContainer {
  display: flex;
  gap: 15px;
  margin-top: 15px;
}

.saveEditingBodyButtonContainer {
  display: flex;
  margin-top: 15px;
  gap: 20px;
}

.saveButtonContainer {
  display: flex;
  margin-top: 25px;
}

.checkBoxContainer {
  display: flex;
  gap: 10px;
  margin: 50px 0;
}