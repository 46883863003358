.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
  max-width: 700px;

  @media only screen and (min-width: 768px) {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    /* gap: 15px; */
  }
}

.cardContainer {
  height: calc(100% - 55px);

  @media only screen and (min-width: 768px) {
    width: auto;
  }
}
