.container {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  width: 70vw;
  cursor: pointer;
  margin-bottom: 20px;

  @media only screen and (min-width: 768px) {
    margin-bottom: unset;
    width: 180px;
  }
}

.image {
  border: 1px solid #222222; /* to be deleted once thumbnails are fixed */
  width: 50vw;
  height: auto;
  border-radius: 10px;
  border: 1px solid #333333; /* THUMBNAIL BORDER */
  margin-bottom: 10px;
  transition: 0.07s linear;
  &:hover {
    border: 1px solid #555555;
  }

  @media only screen and (min-width: 768px) {
    width: 180px;
  }
}

.lessOpacity {
  opacity: 0.2;
}
