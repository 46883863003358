.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 80dvh;
  gap: 40px;
  max-width: 700px;
}

.section {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.question {
  display: flex;
  flex-direction: column;
  gap: 10px;
  :last-child {
    padding-left: 15px;
  }
}

.contact {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
