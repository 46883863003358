.default {
  padding: 11px 26px 11px 26px;
  border-radius: 100px;
  min-width: max-content;
  background: var(--redGradient);
  transition: 0.1s ease-in-out;
  height: min-content;
}

.default:hover {
  scale: 1.08;
}

.outline {
  background: none !important;
  box-shadow: inset 0px 0px 0px 1px white;
  background: none;
  padding: 11px 28px 11px 28px;
}

.white {
  background: white;
  font-weight: 600 !important;
}
/* .white:hover {
  background: var(--athleteRed);
} */

.darkOutline {
  background: none !important;
  box-shadow: inset 0px 0px 0px 1px var(--darkGrey);
  padding: 11px 26px 11px 26px;
}

.darkText {
  color: var(--darkGrey);
}

.blackText {
  color: #000;
}

.whiteText {
  color: #fff;
}

.redText {
  color: var(--athleteRed);
  font-weight: 900;
}

.form {
  background: white;
  font-size: 14px !important;
  font-weight: 700 !important;
  padding: 12px 0px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.formSmall {
  background: white;
  font-size: 14px !important;
  font-weight: 700 !important;
  padding: 12px 26px;
  border-radius: 8px;
  display: flex;
  align-items: center;
}

.nav {
  background: none !important;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 11px !important;
  font-weight: 700 !important;
  letter-spacing: 0.5px;
}

.nav:hover {
  scale: none;
}

.navSelected {
  box-shadow: inset 0px 0px 0px 2px var(--athleteRed);
}

.action {
  background: var(--redGradient);
  font-size: 11px !important;
  font-weight: 700 !important;
  letter-spacing: 0.5px;
  padding-left: 20px;
  padding-right: 20px;
}
.action:hover {
  scale: none;
}

.menu {
  background: rgba(255, 225, 225, 0.2) !important;
  font-size: 11px !important;
  font-weight: 700 !important;
  letter-spacing: 0.5px;
  padding-left: 20px;
  padding-right: 20px;
  backdrop-filter: blur(4px);
}
.menu:hover {
  scale: none;
}

.actionWhite {
  background: white;
  font-size: 11px !important;
  font-weight: 700 !important;
  letter-spacing: 0.5px;
  padding-left: 20px;
  padding-right: 20px;
}
.actionWhite:hover {
  scale: none;
}

.actionSelected {
  background: rgba(255, 255, 255, 0) !important;
  box-shadow: inset 0px 0px 0px 2px var(--athleteRed);
}

/* .actionWhiteSelected {
  background: rgba(255, 255, 255, 0) !important;
  box-shadow: inset 0px 0px 0px 1.7px white;
} */
