.outerContainer {
  display: flex;
}

.container {
  background-color: transparent;
  transition: 0.05s linear;
}

.outline {
  border: 1px solid #707070;
  border-radius: 12px;
  padding: 10px 15px;
  &:hover {
    border: 1px solid transparent;
    background-color: var(--background-tertiary);
  }
}

.red {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background-color: red;
  padding: 10px 15px;
}

.primary {
  background-color: var(--background-tertiary-translucent);
  padding: 7px 18px 9px 18px;
  border-radius: 100px;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  height: min-content;

  &:hover {
    background-color: var(--background-tertiary-hover);
  }
}
