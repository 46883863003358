.container {
  position: absolute;
  top: 0;
  left: 0;
  padding: 50px;
  height: 100%;
  width: calc(100% - 100px);
  backdrop-filter: blur(100px);
}

.extraComponentContainer {
  display: flex;
  align-items: center;
  gap: 20px;
}

.closeContainer {
  cursor: pointer;
}