.container {
  height: 100vh;
  background-color: var(--white);
  overflow: auto;
}

.innerContainer {
  padding: 30px;
}

.userNameTitle {
  font-size: 24px;
  font-weight: bold;
}

.chatContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
}

.title {
  color: var(--red);
  font-size: 20px;
  margin-bottom: 8px;
  font-weight: bold;
}

.titleContainer {
  margin-top: 10px;
  margin-bottom: 10px;
}

.overallScore {
  font-weight: bold;
  font-size: 20px;
}

.sectionTitleContainer {
  margin-top: 10px;
  margin-bottom: 5px;
}

.sectionTitle {
  font-size: 18px;
  font-weight: bold;
}

.subTitle {
  font-size: 14px;
  font-weight: bold;
  color: var(--text-secondary);
}

.notesContainer {
  margin-left: 20px;
}

.note {
  font-size: 13px;
}

.topInfoContainer {
  display: flex;
  margin-top: 20px;
}

.auditInfoContainer {
  padding: 20px;
  width: 20%;
  min-width: 220px;
  border-top: 1px solid #CCC;
  border-right: 1px solid #CCC;
  border-bottom: 1px solid #CCC;
}

.resultsOverviewContainer {
  padding: 20px;
  width: 80%;
  min-width: 500px;
  border-top: 1px solid #CCC;
  border-bottom: 1px solid #CCC;
}

.resultsOverviewInnerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
}

.contentContainer {
  display: flex;
}

.leftSideContainer {
  padding: 20px;
  width: 50%;
  border-right: 1px solid #CCC;
  border-bottom: 1px solid #CCC;
  min-width: 350px;
}

.rightSideContainer {
  padding: 20px;
  width: 50%;
  min-width: 350px;
  border-bottom: 1px solid #CCC;
}

.label {
  font-weight: bold;
}

.howToImproveListContainer {
  margin-top: 10px;
  margin-left: 20px;
}