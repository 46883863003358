.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #00000063;
  z-index: 1000;
  backdrop-filter: blur(4px);
}

.contentContainer {
  position: relative;
  background-color: var(--background-secondary);
  box-shadow: 0px 0px 12px 5px rgba(0, 0, 0, 0.4);
  padding: 30px 30px 30px 30px;
  border-radius: 10px;
  overflow-y: scroll;
  max-height: calc(70vh - 50px);
  max-width: 80vw;
  @media (min-width: 500px) {
    min-width: 400px;
  }
}

.closeIconContainer {
  user-select: none;
  position: absolute;
  top: 25px;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  width: 35px;
  border-radius: 100px;
  transition: 0.05s linear;
  cursor: pointer;
  box-shadow: 0px 0px 0px 1px var(--background-tertiary);
}

.closeIconContainer:hover {
  background-color: var(--background-tertiary);
}
