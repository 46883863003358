.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  border-radius: 15px;
  width: 500px;
  height: 700px;
  background-color: #333;
  overflow-y: scroll;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.closeButton {
  cursor: pointer;
}

.tagsContainer {
  margin-top: 20px;
}

.tag {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  margin-top: 5px;
  padding: 5px;
  border-radius: 5px;
  height: 20px;
  &:hover {
    background-color: #555;
  }
}

.icon {
  height: 20px;
}

.buttonsContainer {
  display: flex;
  gap: 20px;
  justify-content: end;
  align-items: center;
  margin-top: 40px;
}