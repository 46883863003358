.videoInProgressCard {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 10px;
  cursor: pointer;

  @media only screen and (min-width: 768px) {
    flex-direction: row;
  }
}

.videoInProgressImage {
  width: 100%;
  height: auto;
  border-radius: 10px;
  border: 1px solid #333333; /* THUMBNAIL BORDER */
  margin-bottom: 10px;
  transition: 0.07s linear;
  &:hover {
    border: 1px solid #555555;
  }

  @media only screen and (min-width: 768px) {
    width: 240px;
    height: 180px;
  }
}

.videoInProgressCardContent {
  margin: 20px 0;
  display: flex;
  gap: 15px;
  flex-direction: column;
  justify-content: space-between;
}

.videoInProgressFooter {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;

  @media only screen and (min-width: 768px) {
    margin-top: 0;
  }
}

.iconContainer {
  display: none;
  @media only screen and (min-width: 768px) {
    display: block;
  }
}
