.container {
  background-color: transparent;
  border-radius: 20px;
  padding: 8px 20px;
  cursor: pointer;
}

.contentContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.showGap {
  gap: 10px;
}

.disabled {
  cursor: not-allowed;
  background-color: var(--background-tertiary) !important;
}

.primary {
  background-color: var(--background-primary);

  &:hover {
    background-color: var(--background-primary-hover);
  }
}

.outlinePrimary {
  box-shadow: 0 0 0 1px var(--border-primary);

  &:hover {
    background-color: var(--background-primary-hover);
  }
}

.secondary {
  background-color: var(--background-secondary);

  &:hover {
    background-color: var(--background-secondary-hover);
  }
}

.outlineSecondary {
  box-shadow: 0 0 0 1px var(--border-secondary);
  &:hover {
    box-shadow: none;
    background-color: var(--background-secondary-hover);
  }
}

.tertiary {
  background-color: var(--background-tertiary);

  &:hover {
    background-color: var(--background-tertiary-hover);
  }
}

.outlineTertiary {
  box-shadow: 0 0 0 1px var(--border-tertiary);

  &:hover {
    box-shadow: none;
    background-color: var(--background-tertiary-hover);
  }
}

.outlineTransparent {
  box-shadow: 0 0 0 1px var(--border-tertiary);
  background-color: transparent;
}

.danger {
  background-color: var(--red);

  &:hover {
    background-color: var(--red-hover);
  }
}

.squareShape {
  border-radius: 12px;
  padding: 15px 22px;
}

.icon {
  padding: 0;
}

.setAsDefault {
  background-color: var(--background-tertiary) !important;
}
