.container {
  display: flex;
  flex-direction: column;
  padding: 125px 20px;
  position: relative;

  @media only screen and (min-width: 768px) {
    padding: 50px;
  }

  /* @media only screen and (min-width: 1368px) { */
  @media only screen and (min-width: 1250px) {
    align-items: center;
  }
}

.contentContainer {
  position: unset;
  /* min-width: calc(768px - var(--nav-bar-size)); */
  width: 100%;

  /* @media only screen and (min-width: 1368px) { */
  @media only screen and (min-width: 1250px) {
    width: 60vw;
    max-width: 1000px;
  }
}

.loadingContentContainer {
  position: relative;
}

.sideLoaderContainer {
  display: flex;
  position: absolute;
  top: 5px;
  left: -30px;
  height: 20px;
  width: 20px;
}

.modalContainer {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  top: 0;
  width: calc(100vw - var(--nav-bar-size));
  backdrop-filter: blur(3px);
  z-index: 100;
}
