.container {
  display: flex;
  gap: 20px;
}

.selected {
  border: 1px solid white;
  border-radius: 100px;
  padding: 3px 10px 5px 10px;
}
