.container {
  margin-top: 20px;
  background-color: #202020;
  padding: 15px;
  border-radius: 10px;
  cursor: pointer;
}

.cardContainer {
  display: flex;
}

.image {
  width: 120px;
  height: 90px;
  margin-right: 30px;
}

.title {
  font-size: 16px;
}

.categoryContainer {
  margin-top: 10px;
}

.deleteButtonContainer {
  margin-top: 20px;
  display: flex;
}