.container {
  z-index: 100;
  position: relative;
}

.contentContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 13px 16px;
  width: 14vw;
  min-width: 190px;
  border-radius: 0 0 var(--borderRadFilters) var(--borderRadFilters);
  cursor: pointer;
}

.filteringText {
  font-size: 11px;
  margin-left: 10px;
}

.filteringContentText {
  font-weight: bold;
}

.popupContainer {
  position: absolute;
  margin-top: 12px;
}
