:root {
  --padding: 40px;
}

.container {
  width: 100%;
}

.cardContainer {
  padding: 40px;
  border-radius: 15px;
  background: var(--background-secondary-translucent);
  position: relative;
  width: 100%;
}

.stackedCardContainer {
  padding: 40px 20px 20px 20px;
  border-radius: 15px;
  background: var(--background-secondary);
  position: relative;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
}

.productImgContainer {
  position: relative;
  transition: all 0.1s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.productImgDescription {
  position: absolute;
  z-index: 2;
}

.imgContainer {
  border-radius: 15px;
  object-fit: cover;
  cursor: pointer;
  height: 100%;
}

.imgContainerStatic {
  position: absolute;
  height: 100%;
  border-radius: 15px;
  object-fit: cover;
  cursor: pointer;
  transition: 0.15s linear;
}

.imgContainerStatic:hover {
  opacity: 0;
}

@media only screen and (max-width: 734px) {
  /* STACKED CONTIANER - currently turned off in favor of the stacking cards */
  .stackedContainer {
    /* display: flex; */
    display: none;
    gap: var(--bigCardSpacing);
  }

  .seperateContainer {
    display: none;
  }
  .inBetweenContainer {
    display: none;
  }

  .extraContentContainer {
    display: flex;
    gap: 10px;
    flex-direction: row;
    margin-top: 20px;
  }

  .imgContainer,
  .imgContainerStatic {
    height: 200px;
    width: 440px;
    margin-top: 40px;
  }

  .contentContainer {
    padding: 0 35px;
  }

  .one,
  .two,
  .three {
    height: 100px;
    width: 80px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50%;
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 735px) and (max-width: 1000px) {
  /* IN BETWEEN CONTAINER */
  .inBetweenContainer {
    display: flex;
  }

  .seperateContainer {
    display: none;
  }

  .stackedContainer {
    display: none;
  }

  .cardContainer {
    background: none;
  }

  .container {
    background: var(--cardBg);
    border-radius: 15px;
  }

  .extraContentContainer {
    display: flex;
    gap: 10px;
    flex-direction: row;
    margin-top: 20px;
  }

  .imgContainer,
  .imgContainerStatic {
    max-width: 300px;
    height: 100%;
  }

  .titleContainer {
    display: flex;
    gap: 18px;
    align-items: center;
  }

  .one,
  .two,
  .three {
    height: 32px;
    width: 30px;
    background-size: contain;
    background-repeat: no-repeat;
  }
}

@media only screen and (min-width: 1000px) {
  /* SEPERATE CONTAINER */
  .seperateContainer {
    /* border: 1px solid red; */
    display: flex;
    gap: var(--bigCardSpacing);
  }

  .stackedContainer {
    display: none;
  }
  .inBetweenContainer {
    display: none;
  }

  .extraContentContainer {
    display: flex;
    gap: 10px;
    flex-direction: row;
    margin-top: 20px;
    position: absolute;
    /* bottom: calc(var(--padding) + 10px); */
    bottom: var(--padding);
  }

  .imgContainer,
  .imgContainerStatic {
    width: 300px;
  }

  .one,
  .two,
  .three {
    height: 150px;
    width: 150px;
    position: absolute;
    bottom: 30px;
    right: 30px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 100%;
  }
}

.contentContainer {
  margin-top: 10px;
  font-size: 17px;
  max-width: 400px;
}

.one {
  background-image: url(../../assets/images/1.svg);
}

.two {
  background-image: url(../../assets/images/2.svg);
}

.three {
  background-image: url(../../assets/images/3.svg);
}

.productImgContainer:hover {
  transform: scale(1.03);
}
