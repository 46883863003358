.title {
  font-size: 30px;
  font-weight: bold;
}

.headerContainer {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  gap: 30px;

  @media only screen and (min-width: 1000px) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.headerSectionContainer {
  margin-top: 20px;
  max-width: 500px;
}

.tagsContainer {
  gap: 15px;
  margin-top: 20px;
  display: flex;
  max-width: 350px;
  flex-wrap: wrap;
}

.sectionContainer {
  margin-top: 30px;
}
