.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  height: clamp(80px, 10vw, 130px);
}

.container {
  background-color: var(--background-primary);
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  height: calc(100vh - 45px - 12px);
  width: calc(42vw - calc(2 * var(--medCardSpacing)));
  padding: 0 var(--medCardSpacing);
  z-index: 99;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  @media (min-width: 1700px) {
    width: calc(35vw - calc(2 * var(--medCardSpacing)));
    max-width: calc(700px - calc(2 * var(--medCardSpacing)));
  }
  @media (max-width: 1000px) {
    width: calc(100% - calc(2 * var(--medCardSpacing)));
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
    border-bottom-right-radius: unset;

    height: calc(100vh - 160px);
    @media (max-width: 768px) {
      height: calc(100vh - 260px);
    }
  }
}

.headerContainer {
  display: flex;
  align-items: center;
  gap: 30px;
  justify-content: space-between;
  width: 100%;
  margin: 40px 0;
  @media (max-width: 440px) {
    flex-direction: column;
    margin-bottom: -9px;
    gap: 20px;
  }
}

.schoolContainer {
  display: flex;
  gap: 20px;
  align-items: center;
}

.schoolNameContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.schoolName {
  color: #fff;
  font-size: clamp(22px, 4vw, 35px);
  font-weight: 600;

  @media (min-width: 1000px) {
    font-size: clamp(22px, 2vw, 35px);
  }
}

.schoolAdress {
  /* color: #f00; */
  color: white;
}

.saveButton {
  user-select: none;
  color: #fff;
  cursor: pointer;
  box-shadow: inset 0px 0px 0px 1px var(--dark-accent-primary);
  padding: 14px 14px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  width: calc(74px - 28px);
  min-width: calc(74px - 28px);
  gap: 5px;
  place-items: center;
  font-size: 14px;
  text-align: center;
  transition: 0.15s;

  @media (max-width: 440px) {
    flex-direction: row;
    width: calc(100% - 30px);
    border-radius: 15px;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }
}

.saveButton:hover {
  background: var(--background-primary-hover);
  box-shadow: none;
}

.saveBtnTextContainer {
  @media (min-width: 440px) {
    height: 34px;
  }
}

.savedTextContainer {
  @media (min-width: 440px) {
    transform: translateY(3px);
  }
}

.savedCheckContainer {
  @media (max-width: 440px) {
    display: none;
  }
}

.schoolLogoContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  /* @media (min-width: 1000px) {
    display: none;
  } */
}

.schoolLogo {
  width: clamp(80px, 10vw, 130px);
  height: clamp(80px, 10vw, 130px);
  /* width: 100px;
  height: 100px; */
}

.pressLogoText {
  color: #fff;
  text-align: center;
}

.contentContainer {
  margin: var(--medCardSpacing) 0;
  background: var(--background-secondary);
  border-radius: var(--cornerRadius);
  padding: 20px;
}

.contentTitle {
  color: #fff;
  font-size: 22px;
  font-weight: 600;

  @media (min-width: 1200px) {
    font-size: 30px;
  }
}

.quickInfoContent {
  margin-top: 10px;
}

.quickInfoGrid {
  margin-top: 15px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
  margin-bottom: 12px;
}

.quickInfoContainer {
  border-radius: 8px;
  padding: 10px;
  background: var(--background-tertiary);
  display: flex;
  align-items: center;
  justify-content: center;
}

.quickInfo {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  height: min-content;

  @media (min-width: 1200px) {
    font-size: 20px;
  }
}

.demo {
  filter: blur(5px);
  margin-left: 6px;
  user-select: none;
}

.quickInfoDescription {
  color: #999999;
  margin-top: 5px;
  font-size: 14px;
  font-weight: 400;
}

.wiggle {
  animation: wiggle 0.15s ease-in-out;
}

@keyframes wiggle {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(5px);
  }
  50% {
    transform: translateX(-5px);
  }
  75% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
  }
}
