.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  height: 100vh;
  width: 100vw;
  background-color: var(--background-primary);
}
