:root {
  --topPadding: 20px;
  --bottomPadding: 6px;
  --columnTopPadding: 20px;
  --listTopMargin: 12px;
  --listTopMarginExtra: 22px;
}

.createFree {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.priceContainer {
  height: 44px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.priceContainerExtraMargin {
  gap: 2px;
  margin-top: 8px;
}

.priceToggleContainerHolder {
  display: flex;
  margin-bottom: 50px;
  justify-content: center;
  @media only screen and (max-width: 530px) {
    margin-bottom: 20px;
  }
}

.priceToggleContainer {
  user-select: none;
  display: flex;
  /* gap: 8px; */
  padding: 8px 8px;
  border-radius: 100px;
  box-shadow: inset 0px 0px 0px 1px var(--darkGrey);
  cursor: pointer;
  position: relative;
  -webkit-tap-highlight-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.priceToggleText {
  cursor: pointer;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90px;
  height: 35px;
  z-index: 10;
}

.yearlyPriceToggleText {
  width: 75px;
}

.priceToggleInput {
  position: absolute;
  display: none;
  transition: all 0.3s ease;
}

.priceToggleSlider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  transition: all 0.3s ease;
}

.priceToggleSlider:before {
  border-radius: 100px;
  color: rgba(255, 0, 0, 0);
  position: absolute;
  content: "";
  width: 90px;
  height: 35px;
  left: 8px;
  bottom: 8px;
  background: var(--redGradient);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  transition: all 0.3s ease;
}

input:checked + .priceToggleSlider:before {
  width: 75px;
  left: calc(8px + 90px);
  /* -webkit-transform: translateX(100px);
  -ms-transform: translateX(100px);
  transform: translateX(100px); */
}

@media only screen and (min-width: 550px) {
  .dealContainer {
    margin-top: var(--pageTopMargin);
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .dealContentContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: none;
    box-shadow: inset 0px 0px 0px 1px var(--darkGrey);
    padding: calc(var(--padding) + 10px) var(--padding);
    border-radius: var(--cornerRadius);
  }

  .buttonContainer {
    display: flex;
    gap: 15px;
    right: 0px;
    margin-left: 30px;
  }

  .createFree {
    gap: var(--bigCardSpacing);
  }

  @media only screen and (max-width: 730px) {
    .buttonContainer {
      display: flex;
      flex-direction: column;
      gap: 15px;
      right: 0px;
      margin-left: 30px;
    }
  }
}

@media only screen and (max-width: 550px) {
  .dealContainer {
    box-shadow: inset 0px 0px 0px 1px var(--darkGrey);
    padding: 35px 25px;
    border-radius: var(--cornerRadius);
    margin-top: var(--cardSpacing);
  }

  .dealContentContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: none;
  }

  .buttonContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    right: 0px;
    margin-top: 20px;
  }

  .createFree {
    gap: var(--cardSpacing);
  }
}

@media only screen and (max-width: 530px) {
  .plansContainer {
    display: none;
  }

  .plansCarousel {
    width: 100%;
  }
}
@media only screen and (min-width: 530px) {
  .plansContainer {
    display: flex;
    gap: 8px;
    justify-content: stretch;
    width: 100%;
  }

  .plansCarousel {
    display: none;
  }
}

.carouselChecklist {
  padding: 0 30px;
  font-size: 20px;
  margin: var(--listTopMargin) 0px;
  display: flex;
  flex-direction: column;
  gap: 16.3px;
  align-items: center;
}

.carouselChecklist > * {
  display: flex;
  gap: 10px;
  justify-content: center;
}

.carouselChecklist img {
  width: 18px;
  margin-top: 1px;
  user-select: none;
}

.plansColumnsContainer {
  max-width: min-content;
  overflow-x: scroll;
  display: flex;
  padding: var(--topPadding) var(--topPadding);
  padding-bottom: var(--bottomPadding);
  background: var(--cardBg);
  border-radius: 0px var(--cornerRadius) var(--cornerRadius) 0px;
}

.plansColumn {
  padding: var(--columnTopPadding) 0px;
  border-radius: var(--cornerRadius);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.carouselPlansColumn {
  padding: var(--columnTopPadding) 0px;
  border-radius: var(--cornerRadius);
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--cardBg);
  gap: 10px;
}

.plansColumn:hover {
  background: rgba(255, 255, 255, 0.07);
  cursor: pointer;
}

.preSelected {
  & .selectButton {
    box-shadow: none;
    background: var(--redGradient) !important;
  }
  background: rgba(255, 255, 255, 0.07);
  cursor: pointer;
}

.plansColumn:active {
  animation: shake 1s infinite;
}

.plansColumn {
  &:hover .selectButton {
    box-shadow: none;
    background: var(--redGradient) !important;
  }
  transition: 0.4s;
}

.selectButton {
  padding: 11px 36px 11px 36px !important;
  background: none !important;
  box-shadow: inset 0px 0px 0px 1px white;
  background: none;
}
.carouselSelectButton {
  padding: 11px 36px 11px 36px !important;
  background: none !important;
  box-shadow: inset 0px 0px 0px 1px white;
  background: var(--redGradient);
}

.coachesContainer {
  &:hover .coachesSelectButton {
    background: var(--redGradient) !important;
    box-shadow: none;
  }
  transition: 0.4s;
}

@media only screen and (max-width: 730px) {
  .coachesContainer {
    display: none;
  }
  .coachesContainerUnder {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background: none;
    align-items: center;
    box-shadow: inset 0px 0px 0px 1px var(--darkGrey);
    border-radius: var(--cornerRadius);
    padding: 35px 25px;
    margin-top: var(--cardSpacing);
    gap: 30px;
    width: calc(100% - 50px);
  }
}

@media only screen and (min-width: 730px) {
  .coachesContainerUnder {
    display: none;
  }

  .coachesContainer {
    width: min-content;
    position: relative;
    margin-left: 10px;
    display: flex;
    box-shadow: inset 0px 0px 0px 1px var(--darkGrey);
    border-radius: var(--cornerRadius);
    padding: 50px 40px;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}

.coachesContainerUnder:hover {
  box-shadow: none;
  background: var(--cardBg);
  cursor: pointer;
}

.coachesContainerUnder {
  &:hover .coachesSelectButton {
    background: var(--redGradient) !important;
    box-shadow: none;
  }
  transition: 0.4s;
}

.coachesUnderText {
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-width: 340px;
}

.coachesSelectButton {
  padding: 11px 26px 11px 26px !important;
  background: none !important;
  box-shadow: inset 0px 0px 0px 1px white;
  background: none;
}
.coachesSelectButtonUnder {
  padding: 11px 26px 11px 26px !important;
}

.featuresContainer {
  padding: var(--topPadding) 30px;
  background: var(--cardBg);
  border-radius: var(--cornerRadius) 0px 0px var(--cornerRadius);
}

.featuresTitle {
  margin-top: var(--columnTopPadding);
  display: flex;
  flex-direction: column;
}

.discountInputContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  @media only screen and (max-width: 530px) {
    margin-bottom: 25px;
    margin-top: 0px;
  }
}

.discountInput {
  border-radius: 7px;
  box-shadow: inset 0px 0px 0px 1px var(--darkGrey);
  width: 10px;
  flex: 1;
  height: 20px;
  background-color: transparent;
  padding: 5px 8px;
  border: none;
  outline: none;
  color: #fff;
  @media only screen and (max-width: 530px) {
    border-radius: var(--cornerRadius);
    padding: 15px;
    font-size: 15px;
    width: calc(100% - 30px);
  }
}

.discountInput:focus {
  box-shadow: inset 0px 0px 0px 0.7px white;
}
.discountInput::placeholder {
  font-style: italic;
}

.loadingContainer {
  display: flex;
  align-items: center;
  position: absolute;
  right: 20px;
  scale: 0.6;
  @media only screen and (max-width: 530px) {
    right: 38px;
    scale: 0.9;
  }
}

.discountCancelButton {
  display: flex;
  align-items: center;
  opacity: 20%;
  position: absolute;
  right: 7px;
  @media only screen and (max-width: 530px) {
    right: 18px;
    scale: 1.5;
  }
}

.discountCancelButtonActive {
  cursor: pointer;
  opacity: 100%;
}

.columnTitle {
  display: flex;
  flex-direction: column;
  gap: 8px;
  text-align: center;
}

.featuresList {
  margin-top: 20px;
  min-width: min-content;
  text-wrap: nowrap;
  display: flex;
  flex-direction: column;
  gap: 14.2px;
}

.featuresListExtraMargin {
  margin-top: 38px;
}

.checklist {
  margin: var(--listTopMargin) 0px 30px 0px;
  display: flex;
  flex-direction: column;
  gap: 16.3px;
  align-items: center;
}

.checklistExtraMargin {
  margin-top: var(--listTopMarginExtra);
}

.checklist img {
  width: 18px;
  margin-top: 1px;
  user-select: none;
}

.plansButton {
  padding: 0px 28px;
}

.coachesContainer:hover {
  box-shadow: none;
  background: var(--cardBg);
  cursor: pointer;
}

.coachesButton {
  position: absolute;
  bottom: calc(28px + var(--bottomPadding));
  padding: 0px 28px;
}

.coachesText {
  margin: 20px 0px;
}

.tooltip {
  padding: 2px 0;
  width: fit-content;
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltip .tooltiptext {
  opacity: 0;
  transition: opacity 0.5s;
  visibility: hidden;
  min-width: 15em;
  text-wrap: wrap;
  background-color: #414141a2;
  color: #fff;
  font-size: smaller;
  text-align: center;
  border-radius: 10px;
  padding: 12px 16px;
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 110%;
  transform: translateY(-50%);
  backdrop-filter: blur(7px);
}

.tooltip .tooltiptext::after {
  content: " ";
  position: absolute;
  top: 50%;
  right: 100%; /* To the left of the tooltip */
  margin-top: -7px;
  border-width: 7px;
  border-style: solid;
  border-color: transparent #414141a2 transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.checklistTooltip {
  width: fit-content;
  position: relative;
  display: inline-block;
  cursor: help;
}

.checklistTooltip .checklistTooltiptextLeft {
  opacity: 0;
  transition: opacity 0.1s;
  visibility: hidden;
  min-width: max-content;
  /* max-width: 20em; */
  background-color: #414141a2;
  color: #fff;
  font-size: smaller;
  text-align: center;
  border-radius: 10px;
  padding: 6px 12px;
  position: absolute;
  z-index: 1;
  top: 10%;
  transform: translateY(-50%);
  backdrop-filter: blur(7px);
}
.checklistTooltip .checklistTooltiptextRight {
  opacity: 0;
  transition: opacity 0.1s;
  visibility: hidden;
  min-width: max-content;
  /* max-width: 20em; */
  background-color: #414141a2;
  color: #fff;
  font-size: smaller;
  text-align: center;
  border-radius: 10px;
  padding: 6px 12px;
  position: absolute;
  z-index: 100;
  top: 10%;
  transform: translateY(-50%);
  backdrop-filter: blur(7px);
}

.checklistTooltiptextLeft {
  right: 150%;
}

.checklistTooltiptextRight {
  left: 150%;
}

.checklistTooltip:hover .checklistTooltiptextLeft {
  visibility: visible;
  opacity: 1;
}
.checklistTooltip:hover .checklistTooltiptextRight {
  visibility: visible;
  opacity: 1;
}

@keyframes shake {
  0% {
    transform: translate(0.5px, 0.5px) rotate(0deg);
  }
  10% {
    transform: translate(-0.5px, -1px) rotate(-0.5deg);
  }
  20% {
    transform: translate(-1.5px, 0px) rotate(0.5deg);
  }
  30% {
    transform: translate(1.5px, 1px) rotate(0deg);
  }
  40% {
    transform: translate(0.5px, -0.5px) rotate(0.5deg);
  }
  50% {
    transform: translate(-0.5px, 1px) rotate(-0.5deg);
  }
  60% {
    transform: translate(-1.5px, 0.5px) rotate(0deg);
  }
  70% {
    transform: translate(1.5px, 0.5px) rotate(-0.5deg);
  }
  80% {
    transform: translate(-0.5px, -0.5px) rotate(0.5deg);
  }
  90% {
    transform: translate(0.5px, 1px) rotate(0deg);
  }
  100% {
    transform: translate(0.5px, -1px) rotate(-0.5deg);
  }
}

/* width */
.plansColumnsContainer::-webkit-scrollbar {
  height: 28px;
}

/* Track */
.plansColumnsContainer::-webkit-scrollbar-track {
  background: none;
}

/* Handle */
.plansColumnsContainer::-webkit-scrollbar-thumb {
  background: var(--darkGrey);
  border-radius: 100px;
  background-clip: content-box;
  border: 10px solid transparent;
}

/* Handle on hover */
.plansColumnsContainer::-webkit-scrollbar-thumb:hover {
  background: #333;
  border-radius: 100px;
  background-clip: content-box;
  border: 10px solid transparent;
}

.hoverToContainer {
  margin-top: -7px;
  margin-bottom: 12px;
  font-size: 14px;
}
