.title {
  margin-left: 20px;
  display: flex;
  gap: 10px;
  align-items: center;
}

.content {
  margin-top: 10px;
}
