.infoContainer {
  margin-top: 40px;
}

.valueContainer {
  margin-top: 10px;
}

.textAreaContainer {
  margin-top: 40px;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  padding: 10px;
  margin-top: 40px;
}
