.containerClassName {
  flex: 1;
}

.dropdownContainer {
  background-color: #FFFFFF22;
  color: #FFF;
  border: none;
  border-radius: 8px;
  padding: 8px 15px;
}

.menuClassName {
  background-color: #323232;
  border: none;
  border-radius: 8px;
  :global(.Dropdown-option) {
    color: #FFF;
  }
  :global(.Dropdown-option:hover) {
    background-color: #FFFFFF22;
  }
  :global(.Dropdown-option.is-selected) {
    background-color: #FFFFFF22;
  }
}

.label {
  margin-left: 15px;
}

.contentContainer {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
  width: 100%;
}