.container {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  /* @media only screen and (max-width: 678px) {
    width: min-content;
  } */
}

.leftRightContainer {
  display: flex;
  width: 100%;
  gap: 30px;
  justify-content: space-between;
  @media only screen and (max-width: 678px) {
    flex-direction: column;
  }
}

.leftContainer {
  min-width: max-content;
}

.rightContainer {
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: flex-end;
}

.contentContainer {
  margin-top: 25px;
}

.sectionContainer {
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: 10px;
}

.paymentContainer {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.paymentContentContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
}

.card {
  display: flex;
  margin-top: 10px;
  border: 1px solid var(--border-secondary);
  border-radius: 10px;
  gap: 30px;
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0 15px;
}

.changePaymentButton {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  border-radius: 10px;
  background: var(--background-tertiary);
  transition: 0.05s linear;
  max-width: 240px;
}

.changePaymentButton:hover {
  background: var(--background-tertiary-hover);
}

.buttonsContainer {
  display: flex;
  gap: 20px;
  margin-top: 30px;
  justify-content: flex-end;
  align-items: center;
}

.couponInput {
  margin-top: 10px;
  width: 250px;
}

.danger {
  font-weight: 500;
  color: red;
}

.addPaymentContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 10px;
}

.discountInputContainer {
  width: 250px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.discountInput {
  border-radius: 10px;
  border: 1px solid var(--border-secondary);
  width: 100%;
  height: 20px;
  background-color: transparent;
  outline: none;
  color: #fff;
  height: 40px;
  padding-left: 10px;
}

.discountInput:focus {
  border: 1px solid white;
}
.discountInput::placeholder {
  font-style: italic;
}

.loadingContainer {
  display: flex;
  align-items: center;
  position: absolute;
  right: 32px;
  scale: 0.8;
}

.discountCancelButton {
  display: flex;
  align-items: center;
  opacity: 20%;
  position: absolute;
  right: 13px;
}

.discountCancelButtonActive {
  cursor: pointer;
  opacity: 100%;
}

.summaryContainer {
  display: flex;
  gap: 5px;
  flex-direction: column;
  justify-self: flex-end;
  align-items: flex-end;
  width: fit-content;
}

.planDiscountContainer {
  text-wrap: nowrap;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 5px;
}

.totalRow {
  margin-bottom: -5px;
}
.totalRow > * {
  font-weight: 600;
}

.divider {
  width: calc(100% + 10px);
  /* width: 100%; */
  border-bottom: 1px solid white;
}

.legalText {
  margin-top: 20px;
  max-width: 30em;
}

.errorMessageContainer {
  margin: 5px 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-right: 50px;
  /* text-align: center; */
  max-width: 400px;
}
