@media only screen and (max-width: 570px) {
  .container {
    display: flex;
    flex-direction: column;
    gap: 30px;
    text-align: center;
    /* width: 100%; */
    align-items: center;
    padding: 40px var(--insetPadding) 40px var(--insetPadding);
  }
}

@media only screen and (min-width: 571px) {
  .container {
    display: flex;
    /* width: 100%; */
    align-items: center;
    justify-content: space-between;
    gap: 30px;
    padding: 40px var(--insetPadding) 40px var(--insetPadding);
  }
}

/* .textContainer {
  margin: 40px var(--insetPadding) 40px var(--insetPadding);
}

.buttonContainer {
  margin: 40px var(--insetPadding) 40px var(--insetPadding);
} */
