.schoolButtonsContainer {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: var(--medCardSpacing);
  /* justify-content: space-between; */
  margin-top: var(--medCardSpacing);

  @media (max-width: 440px) {
    grid-template-columns: repeat(2, 1fr);
    flex-wrap: wrap;
    column-gap: unset;
    gap: 8px;
  }
}

.schoolButtonContainer {
  user-select: none;
  border-radius: var(--cornerRadius);
  background: var(--background-secondary);
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  transition: 0.15s;

  @media (max-width: 440px) {
    flex-direction: row;
    justify-content: center;
    gap: 2px;
    padding: 5px 13px 5px 5px;

    &:last-child {
      grid-column: 1 / 3;
    }
  }
}

.schoolButtonContainer:hover {
  background: var(--background-tertiary);
}

.schoolButtonIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  border-radius: 50px;
  color: #fff;

  @media (min-width: 1200px) {
    width: 60px;
    height: 60px;
  }

  @media (min-width: 1500px) {
    width: 80px;
    height: 80px;
  }
}

.icon {
  width: 30px;
  height: 30px;

  @media (min-width: 1200px) {
    width: 35px;
    height: 35px;
  }

  @media (min-width: 1500px) {
    width: 45px;
    height: 45px;
  }

  @media (max-width: 440px) {
    width: 22px;
    height: 22px;
  }
}

.schoolButtonTitle {
  margin-top: 10px;
  color: #fff;
  text-align: center;
  font-size: 14px;

  @media (min-width: 1200px) {
    font-size: 16px;
  }

  @media (max-width: 440px) {
    text-align: left;
    margin-top: unset;
  }
}

.wiggle {
  -webkit-animation: wiggle 0.15s ease-in-out;
  -moz-animation: wiggle 0.15s ease-in-out;
  -o-animation: wiggle 0.15s ease-in-out;
  animation: wiggle 0.15s ease-in-out;
}

@keyframes wiggle {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(5px);
  }
  50% {
    transform: translateX(-5px);
  }
  75% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
  }
}
