.container {
  z-index: 11;
  width: 100%;
  position: fixed;
  -webkit-tap-highlight-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.navContainer {
  user-select: none;
  display: flex;
  justify-content: center;
  background-image: linear-gradient(
    #0d0d0d 20%,
    #0d0d0db0 60%,
    #0d0d0d15 90%,
    #0d0d0d00
  );
  padding-top: 1.3em;
  padding-bottom: 55px;
  width: 100%;
  /* position: fixed; */
  z-index: 10;
}

.logoContainer {
  align-self: center;
  position: absolute;
  width: 120px;
  left: 0;
}

.logo {
  cursor: pointer;
  padding-top: 5px;
}

@media only screen and (max-width: 1060px) {
  .largeContentContainer {
    display: none;
  }

  .smallContentContainer {
    width: 100%;

    max-width: 1500px;
    display: flex;
    justify-content: center;
    position: fixed;
  }

  .logoContainer {
    padding-left: 2em;
  }

  .hiddenButton {
    opacity: 0;
  }

  .shortMenuButtons {
    padding-right: 2em;
    display: flex;
    gap: 10px;
    position: absolute;
    right: 0;
  }

  .actionButtons {
    transition: 0.15s;
    display: flex;
    gap: 10px;
  }

  @media only screen and (max-width: 600px) {
    .actionButtons {
      visibility: hidden;
    }
  }
}

@media only screen and (min-width: 1060px) {
  .smallContentContainer {
    display: none;
  }

  .largeContentContainer {
    width: 100%;
    max-width: 1500px;
    display: flex;
    justify-content: center;
    position: fixed;
  }

  .logoContainer {
    padding-left: 50px;
  }

  .navButtons {
    display: flex;
    box-shadow: inset 0px 0px 0px 1.5px var(--darkGrey);
    border-radius: 100px;
  }

  .actionButtons {
    padding-right: 50px;
    display: flex;
    gap: 10px;
    position: absolute;
    right: 0;
  }
}

.menuButtonContent {
  display: flex;
  max-height: min-content;
  align-items: center;
  gap: 7px;
}

#icon {
  width: 15px;
  height: 8px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

#icon span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: white;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

#icon span:nth-child(1) {
  top: 0;
  -webkit-transform-origin: center;
  -moz-transform-origin: center;
  -o-transform-origin: center;
  transform-origin: center;
}

#icon span:nth-child(2) {
  bottom: 0;
  -webkit-transform-origin: center;
  -moz-transform-origin: center;
  -o-transform-origin: center;
  transform-origin: center;
}

#icon.opened span:nth-child(1) {
  width: 15px;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: translate(0px, 3px) rotate(45deg);
}

#icon.opened span:nth-child(2) {
  width: 15px;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: translate(0px, -3px) rotate(-45deg);
}

.menuButton {
}

.sidenavContainer {
  width: 0;
  max-width: 90%;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(25px);
  right: 0;
  position: absolute;
  transition: 0.3s;
}

.sidenavNormalHeight {
  height: 100dvh;
}
.sidenavBannerHeight {
  height: calc(100dvh - 38px);
}

.sidenavContent {
  height: calc(100% - 60px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 30px 30px;
}

.sidenavContentBannerOn {
  height: calc(100vh - 60px - 38px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 30px 30px;
}

.sidenavPagesContainer {
  margin-top: 8vh;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 340px;
  justify-content: space-between;
  min-width: max-content;
}

.sidenavPage {
  cursor: pointer;
  font-size: 24px;
  font-weight: 500;
}

.sidenavBottomContentContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 30px;
}
