.text {
  color: #fff;
  font-weight: 300;
}

.textDark {
  font-weight: 400;
  color: #000;
}

.container {
  background-color: var(--background-tertiary);
  /* background-color: #3a3a3ad8;
  backdrop-filter: blur(10px); */
  box-shadow: 0px 4px 3px -1px rgb(0, 0, 0, 0.2);
  transition: 0.1s linear;
}

.containerSecondary {
  background-color: var(--background-quaternary);
}

.hover:hover {
  background-color: var(--background-quaternary);
}

.containerDark {
  background-color: var(--background-tertiary-light);
  box-shadow: 0px 4px 5px -1px rgb(0, 0, 0, 0.6);
  transition: 0.1s linear;
}

.containerDarkSecondary {
  background-color: var(--background-quaternary-light);
}

.hoverDark:hover {
  background-color: var(--background-quaternary-light);
}
