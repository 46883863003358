body {
  font-family: "Inter", sans-serif;
}

.container {
  box-sizing: border-box;
  height: 100%;
  width: calc(100vw - var(--nav-bar-size));
  overflow: hidden;
  display: flex;
  position: fixed;
  @media only screen and (max-width: 768px) {
    padding-top: 60px;
    width: 100%;
  }
}

.closedNavBarContainer {
  width: calc(100vw - var(--nav-bar-closed-size));
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
}

.demoContainer {
  width: 100vw;
  padding-top: unset;
}

.demoVersionContainer {
  position: fixed;
  z-index: 999;
  color: #fff;
  bottom: 15px;
  left: 50vw;
  background-color: #080808a9;
  border-radius: 10px;
  padding: 10px 20px;
  backdrop-filter: blur(6px);
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  cursor: pointer;
  animation: bounce 10s infinite ease-in-out;

  @media only screen and (max-width: 1000px) {
    animation: bounce-down 10s infinite ease-in-out;
    left: calc(50vw - 110px);
    bottom: unset;
    top: 70px;
  }
}

.demoVersionContainerDark {
  background-color: #ffffffa9;
  color: black;
}

.getAccessBtn {
  color: white;
  text-decoration: underline;
}
.getAccessBtnDark {
  color: black;
  text-decoration: underline;
}

.demoStartupContainer {
  user-select: none;
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.demoStartup {
  color: #fff;
  background-color: #080808a9;
  border-radius: 15px;
  padding: 20px 30px 30px 30px;
  backdrop-filter: blur(6px);
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  gap: 10px;
  position: relative;

  @media only screen and (max-width: 1000px) {
    background-color: var(--background-tertiary);
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.384);
  }
}

.startupCloseIcon {
  position: absolute;
  top: 14px;
  right: 14px;
  cursor: pointer;
}

.schoolOutterContainer {
  position: absolute;
  display: flex;
  left: -15px;
  top: -15px;
  flex-direction: column;
  align-items: center;
}

.schoolContainer {
  height: 30px;
  width: 30px;
  transition: 0.2s;
}

.selectedSchoolContainer {
  transform: scale(2);
  z-index: 100;
}

.schoolContainer:hover {
  transform: scale(1.5);
  z-index: 100;
}

.hoveringContainer {
  padding: 20px 30px;
  height: min-content;
  position: absolute;
  bottom: 10px;
  right: 60px;
  z-index: 102;
  border-radius: 10px;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 28vw;

  @media (min-width: 1000px) {
    display: flex;
    gap: 5px;
  }
}

.hoveringContainerSchoolName {
  text-align: center;
  font-size: 25px;
}

.hoveringContainerSchoolAddress {
  font-size: 15px;
}

.mapSpacer {
  width: 0vw;
  background-color: #000;
  display: none;

  @media (min-width: 1000px) {
    display: block;
  }
}

.schoolInfoContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--background-primary);
  border-radius: 15px;
  padding: 10px 18px;
  gap: 4px;
}

.schoolInfoContainerSelected {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--background-primary);
  border-radius: 15px;
  padding: 10px 18px;
  margin-top: 30px;
  z-index: 100;
  gap: 4px;
}

.schoolNameText {
  color: #fff;
  font-size: 13.5px;
  text-wrap: nowrap;
}

.schoolAddressText {
  text-wrap: nowrap;
  color: #999999;
}

@keyframes bounce {
  0%,
  92%,
  96%,
  100% {
    transform: translateY(0);
  }
  94% {
    transform: translateY(-10px);
  }
  98% {
    transform: translateY(-5px);
  }
}
@keyframes bounce-down {
  0%,
  92%,
  96%,
  100% {
    transform: translateY(0);
  }
  94% {
    transform: translateY(8px);
  }
  98% {
    transform: translateY(4px);
  }
}
